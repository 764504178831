import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { MESSAGES } from '../assets/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '20px !important',
      width: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cardHeader: {
      paddingBottom: '0 !important',
    },
    iconButton: {
      padding: '0px !important',
    },
    icon: {
      color: theme.palette.primary.main,
    },
    avatar: {
      padding: '0.5rem',
      marginBottom: '1rem',
      backgroundColor: `${theme.palette.success.main} !important`,
      width: '60px !important',
      height: '60px !important',
    },
    successIcon: {
      color: 'white',
      fontSize: '60px !important',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '0px !important',
    },
    modalText: {
      textAlign: 'center'
    }
  })
);

const SuccessModal = ({ open, onClose }: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal open={open}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          action={
            <IconButton className={classes.iconButton} onClick={onClose}>
              <CancelIcon className={classes.icon} fontSize="large" />
            </IconButton>
          }
        />
        <CardContent className={classes.cardContent}>
          <Avatar className={classes.avatar}>
            <CheckRoundedIcon className={classes.successIcon} />
          </Avatar>

          <Typography component="h5" variant="h5" className={classes.modalText}>
            {MESSAGES['card.success']}
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default SuccessModal;
