import { createContext, useContext } from 'react';

export const StateContext = createContext({
  counties: [] as any,
  setState: {} as any,
});

export function useStateContext() {
  return useContext(StateContext);
}
