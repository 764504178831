/* eslint-disable @typescript-eslint/no-var-requires */
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  TextField,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import logo from "../assets/images/logo_secom.svg";
import { useAuthContext } from "../auth/AuthContext";
import ErrorModal from "../components/ErrorModal";
import { loginValidations } from "../constants/validations";
import { login } from "../providers/auth.service";
import { MESSAGES } from "../assets/messages";
import { useStateContext } from "../state/StateContext";
import { getCountiesAndCities } from "../providers/locations.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex !important",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      padding: "2rem",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      height: "100%",
      padding: "2rem",
      alignItems: "center",
      justifyContent: "center",
    },
    input: {
      display: "block",
      marginBottom: "1rem !important",
      width: "100%",
    },
    primaryButton: {
      background: theme.palette.primary.main,
      color: "#ffffff !important",
      width: "20%",
    },
  })
);

const Login = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [loginError, setError] = useState("");
  const { setAuthState } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setState } = useStateContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onSubmit", reValidateMode: "onChange" });

  const onSubmit = (data: any) => {
    setIsLoading(true);

    login(data)
      .then((response: any) => {
        if (response) {
          setAuthState({ isAuthenticated: true });
          setIsLoading(false);
          getCountiesAndCities()
            .then((countiesResponse) => {
              setState({ counties: countiesResponse });
            })
            .catch((err) => {
              if(err?.response?.data?.message){
                setError(err?.response?.data?.message);
              } else {
                if(!navigator.onLine){
                  setError(MESSAGES[`error.internet.access` as string]);
                } else{
                  setError(MESSAGES[`error.500` as string]);
                }
              }
            });
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        if (error?.response?.data?.statusCode === 401) {
         setError(MESSAGES[`login.error.${error.response.data.statusCode}`]);
        } else {
          if(!navigator.onLine){
            setError(MESSAGES[`error.internet.access` as string]);
          } else{
            setError(MESSAGES[`error.500` as string]);
          }
        } 
      });
  };

  return (
    <>
      <Container className={classes.container}>
        <img src={logo} alt="Secom Leads" />
        {isLoading && (
          <Backdrop open={isLoading} style={{ zIndex: 100 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )}
        <FormControl className={classes.form}>
          <Controller
            name="username"
            control={control}
            rules={loginValidations.username}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value || ""}
                  label={MESSAGES["username"]}
                  variant="outlined"
                  error={errors["username"] && true}
                  className={classes.input}
                  helperText={MESSAGES[errors["username"]?.message]}
                />
              );
            }}
          />
          <Controller
            name="password"
            control={control}
            rules={loginValidations.password}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value || ""}
                  label={MESSAGES["password"]}
                  variant="outlined"
                  error={errors["password"] && true}
                  className={classes.input}
                  type="password"
                  helperText={MESSAGES[errors["password"]?.message]}
                />
              );
            }}
          />
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            className={classes.primaryButton}
          >
            {MESSAGES["login"]}
          </Button>
        </FormControl>
      </Container>
      <ErrorModal error={loginError} onClose={setError.bind(null, "")} />
    </>
  );
};

export default Login;
