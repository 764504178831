/* eslint-disable @typescript-eslint/no-var-requires */
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  AppBar,
  Button,
  Backdrop,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  Menu,
  Chip,
  FormGroup,
  FormLabel,
  Divider,
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import ro from 'date-fns/locale/ro';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import fivePer from '../assets/images/5per.svg';
import sevenPer from '../assets/images/7per.svg';
import tenpPer from '../assets/images/10per.svg';
import logo from '../assets/images/logo_secom.svg';
import ErrorModal from '../components/ErrorModal';
import { modalType, sourceOptions } from '../constants/constants';
import { formValidations } from '../constants/validations';
import { useStateContext } from '../state/StateContext';
import TextModal from '../components/TextModal';
import { MESSAGES } from '../assets/messages';
import { useAuthContext } from '../auth/AuthContext';
import { createCard } from '../providers/createCard.service';
import { ICreateCard } from '../interfaces/card.interface';
import SuccessModal from '../components/SuccessModal';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { LOCAL_STORAGE_CONSTANTS } from '../constants/api.constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '0',
      padding: 0,
      paddingTop: '4rem',
    },
    toolbar: {
      justifyContent: 'center',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      height: '100%',
      padding: '2rem',
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: 'center',
      justifyContent: 'start',
      margin: '0',
      marginTop: '2rem',
      overflow: 'visible !important',
    },
    discountBanners: {
      justifyContent: 'space-evenly',
      position: 'relative',
      top: '-1.3rem',
    },
    cardContent: {
      width: '80%',
      paddingTop: '0!important',
      paddingBottom: '0!important',
    },
    formGrid: {
      width: '100%',
      top: '-1rem',
    },
    input: {
      width: '100%',
    },
    subInput: {
      width: '50%',
    },
    formButton: {
      width: '100%',
    },
    labelText: {
      color: theme.palette.primary.main,
    },
    toolbarButton: {
      width: '5rem',
      backgroundColor: 'white!important',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
    },
    toolbarButtonContainer: {
      position: 'absolute',
      right: '24px',
    },
    checkLabel: {
      margin: 0,
    },
    toolbarLocationContainer: {
      position: 'absolute',
      left: '1.8%',
    },
    location: {
      fontSize: '0.9rem !important',
      fontWeight: '500 !important',
      paddingLeft: '6px !important',
      paddingRight: '6px !important',
    },
  })
);

const CreateMembership = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { location, setAuthState } = useAuthContext();
  const [hasEmail, setHasEmail] = useState(true);
  const [gender, setGender] = useState('M');
  const [hasKids, setHasKids] = useState<boolean>(true);
  const { counties } = useStateContext();
  const [selectedCounty, setSelectedCounty] = useState<string>();
  const [selectedSource, setSelectedSource] = useState<string>();
  const [tos, setTos] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<boolean>(false);
  const [marketingByEmail, setMarketingByEmail] = useState<boolean>(false);
  const [marketingByPhone, setMarketingByPhone] = useState<boolean>(false);
  const [postError, setPostError] = useState<string>('');
  const [detailsType, setDetailsType] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const formatDate = (date: Date): string => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const onReset = () => {
    reset();
    setHasEmail(true);
    setHasKids(true);
    setGender('M');
    setTos(false);
    setMarketing(false);
    setSelectedCounty('');
  };

  const onCheckTos = () => {
    if (!tos && errors['tos']) {
      clearErrors('tos');
    }
    setTos(!tos);
  };

  useEffect(() => {
    clearErrors('marketing')
  }, [marketing, marketingByEmail, marketingByPhone])

  useEffect(() => {
    setMarketingByEmail(false);
    setMarketingByPhone(false);
  }, [marketing])

  useEffect(() => {
    if (!hasEmail) {
      setMarketingByEmail(false);
    }
  }, [hasEmail])

  const onSubmit = (data: any) => {
    if (!tos) {
      setError('tos', {
        type: 'custom',
        message: 'card.tos.required',
      });
    }
    else if (hasEmail && !data.email) {
      setError('email', {
        type: 'custom',
        message: 'card.email.required',
      });

    } else if (marketing && !(marketingByEmail || marketingByPhone)) {
      setError('marketing', {
        type: 'custom',
        message: 'card.marketing.form.required',
      });
    } else {
      setIsLoading(true);

      const payload: ICreateCard = {
        phone: data.phoneNumber,
        fidelityCardNo: data.cardNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        city: data.city,
        county: data.county,
        birthday: formatDate(data.birthday),
        hasKids,
        sex: gender,
        accepts_tc: tos,
        marketing,
        marketingByEmail,
        marketingByPhone,
        tablet_location: location,
        email: hasEmail ? data.email : null,
        leadSource:
          selectedSource === sourceOptions[2]
            ? data.leadSource
            : MESSAGES[`card.source.${data.leadSourceSelect}`],
      };

      createCard(payload)
        .then(() => {
          setIsLoading(false);
          setSuccess(true);
          onReset();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error?.response?.data?.message) {
            setPostError(error?.response?.data?.message);
          } else {
            if (!navigator.onLine) {
              setPostError(MESSAGES[`error.internet.access` as string]);
            } else {
              setPostError(MESSAGES[`error.500` as string]);
            }
          }

        });
    }
  };

  const logout = () => {
    handleClose();
    onReset();
    localStorage.clear();
    setAuthState({});
  };

  const changeLocation = () => {
    onReset();
    localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.LOCATION);
    setAuthState((val: any) => ({ ...val, location: '' }));
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 1101 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLocationContainer}>
            <Chip color='info' size="small" label={location} classes={{ label: classes.location }} />
          </div>
          <div className={classes.logoContainer}>
            <img src={logo} alt="Secom" />
          </div>
          <div className={classes.toolbarButtonContainer}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="info"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={changeLocation}>Schimba magazin</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Typography variant="h3">Cerere card de fidelitate</Typography>
        <Typography variant="h5">
          Înscrie-te în programul de fidelizare Secom® și beneficiezi de:
        </Typography>
        <Card className={classes.card}>
          <Grid container className={classes.discountBanners} spacing={0}>
            <Grid xs={3} item>
              <img src={fivePer} alt="5%" />
            </Grid>
            <Grid xs={3} item>
              <img src={sevenPer} alt="7%" />
            </Grid>
            <Grid xs={3} item>
              <img src={tenpPer} alt="10%" />
            </Grid>
          </Grid>
          <CardContent className={classes.cardContent}>
            <FormControl className={classes.formGrid}>
              <Grid container spacing={1.7}>
                <Grid xs={12} item>
                  <Controller
                    name="cardNumber"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.cardNumber}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={onChange}
                        value={value}
                        type="tel"
                        label={MESSAGES['card.number']}
                        variant="outlined"
                        className={classes.input}
                        error={errors['cardNumber'] && true}
                        helperText={MESSAGES[errors['cardNumber']?.message]}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="lastName"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.lastName}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={onChange}
                        value={value}
                        label={MESSAGES['card.lastName']}
                        variant="outlined"
                        className={classes.input}
                        error={errors['lastName'] && true}
                        helperText={MESSAGES[errors['lastName']?.message]}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="firstName"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.firstName}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={onChange}
                        value={value}
                        label={MESSAGES['card.firstName']}
                        variant="outlined"
                        className={classes.input}
                        error={errors['firstName'] && true}
                        helperText={MESSAGES[errors['firstName']?.message]}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={8} md={9} item>
                  <Controller
                    name="email"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.email}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={!hasEmail}
                        onChange={onChange}
                        value={value}
                        label={MESSAGES['card.email']}
                        variant="outlined"
                        className={classes.input}
                        error={errors['email'] && true}
                        helperText={MESSAGES[errors['email']?.message]}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={4}
                  md={3}
                  item
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <ToggleButton
                    value="email"
                    onClick={() => {
                      setValue('email', '');
                      clearErrors('email');
                      setHasEmail(!hasEmail);
                    }}
                    selected={!hasEmail}
                    className={classes.input}
                  >
                    {MESSAGES['card.noEmail']}
                  </ToggleButton>
                </Grid>
                <Grid xs={6} item>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ro}
                  >
                    <Controller
                      name="birthday"
                      defaultValue={null}
                      control={control}
                      rules={formValidations.birthDate}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          disableFuture
                          label={MESSAGES['card.birthday']}
                          value={value}
                          onChange={(newDate) => {
                            onChange(newDate);
                          }}
                          showToolbar={false}
                          renderInput={(params) => (
                            <TextField
                              helperText={MESSAGES[errors['birthday']?.message]}
                              variant="outlined"
                              className={classes.input}
                              {...params}
                              error={errors['birthday'] && true}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={6} item>
                  <ToggleButtonGroup
                    value={gender}
                    exclusive
                    onChange={(e, newGender) => {
                      if (newGender) {
                        setGender(newGender);
                      }
                    }}
                    className={classes.input}
                    style={{ height: '100%' }}
                  >
                    <ToggleButton value="M" className={classes.subInput}>
                      {MESSAGES['card.gender.male']}
                    </ToggleButton>
                    <ToggleButton value="F" className={classes.subInput}>
                      {MESSAGES['card.gender.female']}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="phoneNumber"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.phoneNumber}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="tel"
                        onChange={onChange}
                        value={value}
                        label={MESSAGES['card.phone']}
                        variant="outlined"
                        className={classes.input}
                        error={errors['phoneNumber'] && true}
                        helperText={MESSAGES[errors['phoneNumber']?.message]}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item container spacing={1}>
                  <Grid
                    xs={5}
                    md={4}
                    item
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Typography>{MESSAGES['card.hasKids']}</Typography>
                  </Grid>
                  <Grid xs={7} md={8} item>
                    <ToggleButtonGroup
                      value={hasKids}
                      exclusive
                      onChange={(e, kids) => {
                        if (kids !== null) {
                          setHasKids(kids);
                        }
                      }}
                      className={classes.input}
                      style={{ height: '100%' }}
                    >
                      <ToggleButton value={true} className={classes.subInput}>
                        {MESSAGES['card.hasKids.yes']}
                      </ToggleButton>
                      <ToggleButton value={false} className={classes.subInput}>
                        {MESSAGES['card.hasKids.no']}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="county"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.county}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={MESSAGES['card.county']}
                        value={value}
                        onChange={(e) => {
                          setSelectedCounty(e.target.value);
                          onChange(e);
                        }}
                        className={classes.input}
                        select
                        error={errors['county'] && true}
                        helperText={MESSAGES[errors['county']?.message]}
                      >
                        {counties?.map((county: any) => {
                          return (
                            <MenuItem
                              value={county.name}
                              key={`countyOption${county.id}`}
                            >
                              {county.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="city"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.city}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={MESSAGES['card.city']}
                        value={value}
                        onChange={onChange}
                        className={classes.input}
                        select
                        error={errors['city'] && true}
                        helperText={MESSAGES[errors['city']?.message]}
                      >
                        {selectedCounty ? (
                          counties
                            ?.find(
                              (county: any) => county.name === selectedCounty
                            )
                            .cities?.map((city: any) => {
                              return (
                                <MenuItem
                                  value={city.name}
                                  key={`cityOption${city.id}`}
                                >
                                  {city.name}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem value={''} key={``}>
                            {''}
                          </MenuItem>
                        )}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Controller
                    name="leadSourceSelect"
                    defaultValue={''}
                    control={control}
                    rules={formValidations.leadSource}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={MESSAGES['card.source']}
                        value={value}
                        onChange={(e: any) => {
                          setSelectedSource(e.target.value);
                          onChange(e);
                        }}
                        className={classes.input}
                        select
                        error={errors['leadSourceSelect'] && true}
                        helperText={
                          MESSAGES[errors['leadSourceSelect']?.message]
                        }
                      >
                        {sourceOptions.map((option: any) => {
                          return (
                            <MenuItem value={option} key={`option${option}`}>
                              {MESSAGES[`card.source.${option}`]}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </Grid>
                {selectedSource === sourceOptions[2] && (
                  <Grid xs={12} item>
                    <Controller
                      name="leadSource"
                      defaultValue={''}
                      control={control}
                      rules={formValidations.leadSource}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          onChange={onChange}
                          value={value}
                          label={MESSAGES['card.source']}
                          variant="outlined"
                          className={classes.input}
                          error={errors['leadSource'] && true}
                          helperText={MESSAGES[errors['leadSource']?.message]}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid xs={12} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tos}
                        onChange={onCheckTos}
                        size="medium"
                      />
                    }
                    label={
                      <>
                        <p className={classes.checkLabel}>
                          {MESSAGES['card.tos.label'].split('{}')[0]}
                          <span
                            className={classes.labelText}
                            onClick={(e: any) => {
                              e.preventDefault();
                              setDetailsType(modalType.TOS);
                            }}
                          >
                            {MESSAGES['card.tos']}
                          </span>
                          {MESSAGES['card.tos.label'].split('{}')[1]}
                        </p>
                        <FormHelperText
                          error={errors['tos'] && true}
                          style={{ marginLeft: 0 }}
                        >
                          {MESSAGES[errors['tos']?.message]}
                        </FormHelperText>
                      </>
                    }
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={marketing}
                        onChange={setMarketing.bind(null, !marketing)}
                        size="medium"
                      />
                    }
                    label={
                      <p className={classes.checkLabel}>
                        {MESSAGES['card.marketing.label']}
                        <span
                          className={classes.labelText}
                          onClick={(e: any) => {
                            e.preventDefault();
                            setDetailsType(modalType.MARKETING);
                          }}
                        >
                          {MESSAGES['card.marketing']}
                        </span>
                      </p>
                    }
                  />
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid xs={12} item>
                  <p className={classes.checkLabel}>Cum vrei sa primesti informări în scopuri de marketing direct si oferte personalizate?</p>
                  <FormGroup sx={{ flexDirection: 'row', paddingTop: '1rem', justifyContent: 'center' }}>
                    {hasEmail && <FormControlLabel
                      control={
                        <Checkbox
                          checked={marketingByEmail}
                          disabled={!marketing}
                          onChange={setMarketingByEmail.bind(null, !marketingByEmail)}
                          size="medium"
                        />
                      }
                      label={
                        <p className={classes.checkLabel}>
                          {MESSAGES['card.marketing.byEmail']}
                        </p>
                      }
                    />}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={marketingByPhone}
                          disabled={!marketing}
                          onChange={setMarketingByPhone.bind(null, !marketingByPhone)}
                          size="medium"
                        />
                      }
                      label={
                        <p className={classes.checkLabel}>
                          {MESSAGES['card.marketing.byPhone']}
                        </p>
                      }
                    />
                    <FormHelperText
                      error={errors['marketing'] && true}
                      style={{ marginLeft: 0 }}
                    >
                      {MESSAGES[errors['marketing']?.message]}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid xs={6} item>
                  <Button
                    variant="text"
                    onClick={onReset}
                    color="error"
                    className={classes.formButton}
                  >
                    {MESSAGES['card.reset']}
                  </Button>
                </Grid>
                <Grid xs={6} item>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    className={classes.formButton}
                    style={{ color: 'white' }}
                  >
                    {MESSAGES['card.confirm']}
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </CardContent>
        </Card>
      </Container >
      <ErrorModal error={postError} onClose={setPostError.bind(null, '')} />
      <TextModal
        variant={detailsType}
        onClose={setDetailsType.bind(null, '')}
      />
      <SuccessModal open={success} onClose={setSuccess.bind(null, false)} />
    </>
  );
};

export default CreateMembership;
