import axios from 'axios';
import { API_URL, LOCAL_STORAGE_CONSTANTS } from '../constants/api.constants';

export const login = async (data: any): Promise<any> => {
  const apiURL = `${process.env.REACT_APP_API_URL}${API_URL.LOGIN}`;

  return axios.post(apiURL, data).then(async (response: any) => {
    if (response.data.access_token) {
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN,
        response.data.access_token
      );
    }
    return response.data;
  });
};
