export const MODAL_TEXTS: Record<string, string> = {
  tosTitle: "Termeni si conditii",
  tos: `Site-ul www.secom.ro, denumit in continuare “secom.ro” sau “site-ul”, este detinut si administrat de catre Secom Healthcare S.R.L., denumita in continuare “Secom®”, avand urmatoarele date de identificare: Cod Unic de Inregistrare RO 8978457, numar de inregistrare la Registrul Comertului J40/9299/1991, sediul social in Cladirea Equilibrium 1, Str. Gara Herastrau nr. 2, etaj 8, Sector 2, Bucuresti, Romania Tel.: 021 9962; E-mail: info@secom.ro. 

Produsele comercializate pe acest site nu sunt destinate revanzarii!

Folosirea acestui site echivaleaza cu citirea, intelegerea si acceptarea de catre Utilizator a termenilor si conditiilor detaliate mai jos. Recomandam citirea cu atentie a acestora atat la momentul efectuarii primei accesari a site-ului www.secom.ro, dar si periodic. Secom® isi rezerva dreptul de a modifica si actualiza in orice moment continutul acestui site, fara o alta notificare prealabila. Utilizatorii/utilizatorii accepta in mod expres ca simpla utilizare sau accesare a serviciilor oferite de catre secom.ro  semnifica acceptarea deplina si neconditionata a prezentelor conditii, impreuna cu orice modificari ulterioare.

Secom® garanteaza Utilizatorului acces limitat, in interes personal (efectuarea de comenzi online, informare), pe site-ul www.secom.ro si nu ii confera acestuia dreptul de a descarca sau de a modifica partial sau integral, de a reproduce partial sau integral continutul site-ul, de a copia, de a vinde/revinde sau de a exploata site-ul in orice alta maniera, in scopuri comerciale sau contrare intereselor Secom® fara acordul prealabil scris al acesteia.

Daca Secom® nu actioneaza in legatura cu o incalcare a Termenilor si Conditiilor, nu inseamna ca renunta la dreptul sau de a actiona in legatura cu incalcari viitoare sau similare. Daca o instanta constata ca vreo prevedere din acesti Termeni este neexecutabila sau invalida, acea prevedere va fi aplicata in cea mai mare masura permisa de legea aplicabila iar ceilalti Termeni vor ramane in vigoare si pe deplin aplicabili.

Utilizatorul nu poate cesiona, delega sau transfera drepturile sau obligatiile sale cuprinse in acesti Termeni si Conditii.

In caz de divergenta sau neintelegeri intre secom.ro si Utilizator, se vor aplica Termenii si conditiile valabile la momentul Comenzii.

DEFINIȚII
Utilizator  – orice persoana care acceseaza site-ul sau care se va putea inregistra la un moment pe site, fiind interesata de activitatea secom.ro , se aboneaza in vederea primirii de informari periodice, se inscrie ca participant la evenimente organizate de catre detinatorul site-ului sau plaseaza o Comanda si care se obliga implicit sa respectate prezentul set de reguli, fiind raspunzator pentru actiunile sale pe site

Produse si Servicii – orice produs sau serviciu mentionat in Comanda, care urmeaza a fi furnizate de catre Secom®, Utilizatoruluir.

Comanda – un document electronic ce intervine ca forma de comunicare intre Secom® si Utilizator prin care Utilizatorul isi exprima intentia de a achizitiona anumite Produse si/sau Servicii si sa faca plata acestora.

Secom® – societatea Secom Healthcare S.R.L., avand urmatoarele date de identificare: Cod Unic de Inregistrare RO 8978457, inregistrata la Registrul Comertului cu nr. J40/9299/1991, cu  sediul social  in Cladirea Equilibrium 1, Str. Gara Herastrau nr. 2, etaj 8, Sector 2, Bucuresti, Romania

Contract – o Comanda confirmata de catre Secom®, prin care Secom® este de acord sa vanda si sa livreze Produsele si/sau Serviciile iar Utilizatorul este de acord sa le achizitioneze, sa primeasca si sa faca plata acestor Produse si/sau Servicii.

Curier – orice persoana de drept public sau privat care presteaza servicii de curierat rapid.

Newsletter – Direct marketing este actul de a trimite un mesaj comercial, de obicei la un grup de oameni, folosind adresa de e-mail/numarul de telefon. In sensul cel mai larg, fiecare e-mail/SMS trimis unui Utilizator poate fi considerat marketing pe e-mail/prin SMS. De obicei, implica utilizarea e-mailului/SMS-urilor pentru a trimite reclame sau pentru a solicita vanzari sau donatii si este menit sa construiasca loialitate, incredere sau cunoasterea marcilor/brandului. Termenul se refera la trimiterea de mesaje cu scopul de a imbunatati relatia noastra cu Utilizatorii, incurajand loialitatea clientilor Mesajele de direct marketing pot fi trimise doar la o baza de date a clientilor/abonatilor Secom. Daca doresti sa nu mai primest notificari comerciale, poti da unsubscribe din orice email primit de la Secom.ro. 

Oferte personalizate – Metoda de marketing care are ca obiectiv cresterea fidelitatii clientilor printr-o buna cunoastere a fiecaruia dintre ei, permitand astfel o personalizare a ofertei, ce se bazeaza pe identificarea si personalizarea consumatorilor/abonatilor si pe existenta unei oferte actualizate la zi prin informatii provenind de la Utilizator/abonat. In vederea transmiterii de oferte personalitate, Secom va putea utiliza aplicatii si tool-uri de Web Tracking pentru a identifica preferintele dumneavoastra. In toate cazurile, Secom va respecta dreptul la viata privata.

DREPTURILE DE PROPRIETATE INTELECTUALA

Intreg continutul site-ului secom.ro, incluzand, dar fara a se limita la: imagini, texte, elemente de grafica web, scripturi, software, drepturi de proiectare, drepturi de model, patente, marci inregistrate, este integral proprietatea Secom® (sau, dupa caz, a furnizorilor sai) si este aparat de Legea privind dreptul de autor si drepturile conexe si de legile privind proprietatea intelectuala si industriala. Folosirea fara acordul Secom® a oricaror elemente enumerate mai sus se pedepseste conform legislatiei in vigoare. Logo-urile secom.ro sunt marci inregistrate ale Secom®. Utilizarea pe secom.ro a oricarui nume de marci inregistrate ale unor companii terte nu constituie reclama pentru companiile respective.

Pentru orice probleme legate de drepturile de autor, ne puteti contacta pe email la adresa: dpo@secom.ro.

EXONERAREA DE RASPUNDERE

Secom® nu isi asuma responsabilitatea si nu poate fi invinuita pentru pagubele aparute prin folosirea continutului secom.ro. Secom® nu poate garanta ca site-ul, serverele pe care este gazduit, sau e-mailurile trimise de la secom.ro sunt fara virusi sau alte componente informatice cu caracter potential daunator, ca nu contin erori, omisiuni, defectiuni, intarzieri sau intreruperi in operare sau transmitere, caderi de linie sau orice alti factori similari. Utilizatorul foloseste site-ul pe riscul propriu, secom.ro fiind liber de orice raspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea sau accesarea/vizitarea site-ului sau ca urmare a utilizarii informatiilor de pe site. Secom® nu este raspunzatoare de erorile sau omisiunile care pot interveni in redactarea sau prezentarea materialelor de pe site. 

Informatiile incluse pe secom.ro au caracter informativ (pozele produselor sunt cu titlu de prezentare) si sunt puse la dispozitie cu buna-credinta, din surse pe care Secom® le considera de incredere. In cazul in care vreunul dintre articolele publicate sau orice alta informatie intra sub incidenta Legii privind dreptul de autor si drepturile conexe, Utilizatorul este rugat sa aduca acest fapt la cunostinta Secom® la adresa de e-mail info@secom.ro, pentru a putea fi luate masurile legale necesare.

Secom.ro isi rezerva dreptul de a anula Comenzile pentru Produse si/sau Servicii care sunt afisate pe site ca urmare a unor erori tehnice, sau care, din cauza unor erori tehnice prezinta preturi evident eronate/derizorii pentru Produse (preturi pe care le poate aprecia ca fiind eronate/derizorii orice cumparator cu un nivel mediu de pregatire).

Orice eventuale link-uri catre alte site-uri sunt oferite numai in scopul unei accesibilitati sporite a informatiilor, iar secom.ro nu isi asuma nici o responsabilitate sau raspundere pentru continutul acelor site-uri ori pentru produsele sau serviciile promovate sau comercializate prin acele site-uri.

Secom.ro isi rezerva dreptul de a face modificari si actualizari ale acestor Termeni si conditii, precum si ale ofertei, fara o notificare prealabila si fara precizarea motivelor; de asemenea, secom.ro. isi rezerva dreptul de a corecta eventuale omisiuni sau erori in afisare care pot surveni in urma unor greseli de dactilografiere, lipsa de acuratete sau erori ale produselor software, fara a anunta in prealabil. Implicit, secom.ro nu isi asuma raspunderea pentru eventualele erori de pret sau stoc.

Aceste erori nu obliga secom.ro la nicio actiune. Preturile si disponibilitatea produselor comercializate de catre secom.ro pot suferi modificari ulterioare, acest lucru fiind influentat de factori externi precum politica de preturi a producatorilor sau disponibilitatea produselor in stocul acestora. Confirmarea comenzii si implicit a stocului si a pretului de vanzare, este efectuata doar dupa procesarea acesteia de catre agentii secom.ro. Va rugam sa cititi cu atentie Termenii si conditiile, de fiecare data cand folositi acest site.

LIMITAREA ACCESULUI LA SITE

Utilizatorii site-ului secom.ro pot face comentarii si orice alte comunicari, pot sa transmita sugestii, intrebari sau informatii, daca limbajul acestora este civilizat, iar continutul comunicarilor nu este ilegal, obscen, amenintator, defaimator, nu tulbura in nici un fel viata privata a altor persoane, nu incalca drepturile de proprietate intelectuala, nu contine virusi, nu serveste unor campanii de promovare care nu au legatura cu secom.ro, nu sunt e-mailuri in masa sau orice alta forma de spam.

Persoanele care vor utiliza o adresa falsa de e-mail ori vor transmite mesaje electronice sau orice alte comunicari in numele unei alte persoane fizice sau juridice ori in numele oricarei alte entitati vor fi raportate organelor abilitate. Secom.ro nu isi asuma responsabilitatea si nici nu va putea fi obligata la nici un fel de despagubiri pentru orice fel de daune produse de astfel de comunicari. In cazul trimiterii sau afisarii unor materiale/documente care implica texte, replici, recenzii etc, formulate de Utilizatori, se considera ca respectivul Utilizator garanteaza originalitatea lor si acorda secom.ro si afiliatilor/asociatilor sai dreptul neexclusiv, nelimitat, gratuit, irevocabil si retransmisibil de a folosi, reproduce, modifica, adapta, publica, traduce, crea opere derivate, precum si dreptul de a distribui, de a prezenta aceste continuturi oriunde in lume, prin orice mijloace. Utilizatorul garanteaza ca are toate drepturile asupra continutului pe care il afiseaza sau il transmite pe site, prin orice modalitate, astfel incat, prin folosirea acestui continut, sa nu cauzeze prejudicii niciunei terte entitati fizice sau juridice.

PRELUCRAREA DATELOR CU CARACTER PERSONAL

Categoriile de date personale prelucrate pot fi: numele si prenumele, sexul, data si locul nasterii, telefon, adresa (domiciliu/resedinta), e-mail,  date de geolocalizare (incusiv IP) /date de trafic.

Atunci cand un Utilizator isi creeaza un cont pe secom.ro, va primi comunicari comerciale din partea secom.ro doar in masura in care si-a manifestat consimtamantul in mod expres, selectand optiunea corespunzatoare.

In cazul in care un Utilizator al site-ului secom.ro decide sa utilizeze serviciul de consultanta video Linia imunitatii, fie prin intermediul serviciului de chat disponibil pe site, fie prin utilizarea serviciului de call center , aducem la cunostinta Utilizatorului faptul ca vom prelucra date cu caracter personal, dupa cum urmeaza:

in cazul utilizarii serviciului de call-center vom inregistra si vom stoca informatii cu privire la: numarul de telefon al Utilizatorului, data si ora apelului precum si continutul convorbirii ;

in cazul utilizarii serviciului de chat, vom stoca informatiile pe care Utilizatorul le furnizeaza in cadrul conversatiei cu consultantul nostru, care pot include: adresa de email, date generale referitoare la starea de sanatate, data conversatiei si orice alte informatii pe care Utilizatorii le furnizeaza in cadrul conversatiei.

Datele colectate cu privire la newslettere si alerte sunt confidentiale. secom.ro va putea, in functie de preferintele exprimate ale Utilizatorilor, selecta destinatarii carora le va trimite newslettere si alerte.

Conform Regulamentului (UE) 2016/679 al Parlamentului European si al Consiliului din 27 aprilie 2016 privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si privind libera circulatie a acestor date si de abrogare a Directivei 95/46/CE (Regulamentul general privind protectia datelor), secom.ro are obligatia de a prelucra in conditii de siguranta si numai pentru scopurile specificate datele personale furnizate de Utilizatori.

Scopul colectarii datelor cu caracter personal poate fi: indeplinirea obligatiilor legale aflate in sarcina secom.ro, gestiunea economico-financiara, reclama, marketing, publicitate, statistica, servicii de comunicatii electronice, informarea Utilizatorilor privind situatia contului lor de pe secom.ro, informarea Utilizatorilor privind evolutia si starea Comenzilor, precum si alte scopuri pentru care a fost obtinut consimtamantul utilizatorilor.

In ceea ce priveste consultanta video, scopul prelucrarii datelor cu caracter personal, astfel cum au fost acestea identificate este acela de a furniza serviciul solicitat de catre Utilizator, de a asigura imbunatatirea serviciilor oferite Utilizatorilor precum si apararea sau exercitarea unui drept in instanta. Temeiul prelucrarii acestor categorii de date este reprezentat de demersurile Utilizatorului pentru incheierea unui contract (art. 6 alin. (1) lit. b din GDPR) si interesul legitim al Secom cu privire imbunatatirea serviciilor oferite Utilizatorilor sai.

Aducem la cunostinta Utilizatorului faptul ca datele cu caracter personal prelucrate cu ocazia utilizarii serviciului de call-center vor fi stocate pentru o durata de maximum 6 luni de la data folosirii acestui serviciu de catre Utilizator, iar in cazul in care este necesar, pentru o durata egala cu durata termenului legal de prescriptie sau pana la solutionarea definitiva a litigiului, pentru apararea drepturilor in instanta. In ceea ce priveste serviciul de chat, durata de stocare este determinata de manifestarea contrara de vointa a Utilizatorului.

Completarea de catre Utilizatori a formularelor pe site si/sau transmiterea, in orice forma, a acordului utilizatorilor echivaleaza cu acceptarea neconditionata ca aceste informatii sa fie incluse in baza de date a secom.ro si cu utilizarea si prelucrarea de catre secom.ro, afiliatii si colaboratorii acestora, pentru desfasurarea activitatilor enumerate mai sus, inclusiv, dar nelimitandu-se la: furnizori de servicii de curierat (FAN Courier, Urgent Cargus, DPD), logistica (KLG Europe Logistics), servicii de plata / bancare (EuroPayment Services - EuPlatesc.ro), marketing si email marketing (Criteo, Klaviyo si MailAgent).

Utilizatorilor le sunt garantate drepturile prevazute de Regulamentul GDPR- (UE) 2016/679, respectiv dreptul de acces, de opozitie si de interventie asupra datelor, dreptul de a nu fi supus unei decizii individuale. Utilizatorii au dreptul de a se opune/restrictiona cu privire la prelucrarea datelor personale si de a solicita stergerea datelor sau portarea acestora. De asemenea, Utilizatorilor le este recunoscut dreptul de a se adresa justitiei ori de a depune o plangere in fata unei autoritati de supraveghere.

Utilizatorul poate solicita oricare dintre drepturile enumerate anterior in baza unei cereri scrise, semnate si datate, la adresa: dpo@secom.ro. Secom Healthcare S.R.L. prelucreaza datele cu caracter personal, acestea putand fi transferate in strainatate (in functie de localizarea partenerilor secom.ro).

Refuzul furnizarii acestor date determina imposibilitatea onorarii Comenzilor.

In cazul in care Utilizatorul isi modifica, prin utilizarea formularelor de pe site, datele personale livrate deja catre secom.ro si exista Comenzi in derulare, Comenzile isi pastreaza datele de la momentul plasarii acestora  iar livrarea produselor se va face luandu-se in considerare datele anterior furnizate.

Secom.ro nu solicita Utilizatorilor sai prin niciun mijloc de comunicare (telefon/e-mail etc) informatii confidentiale, date despre conturi bancare, parole personale etc. In cazul in care Utilizatorul le divulga unor terti, acesta poarta intreaga responsabilitate pentru actiunile sale. Astfel, in cazuri de divulgare a unor asemenea date, secom.ro nu poate fi tinut raspunzator pentru niciun fel de prejudiciu.

Anumite informatii ce pot fi date cu caracter personal (prenume, adresa de e-mail, judet) sunt transmise pe o durata limitata de timp catre serviciul partener “Opinii de Incredere by TRUSTED.ro”, in scopul trimiterii unui mesaj in numele magazinului, pentru a gestiona in mod independent opinia Utilizatorilor cu privire la magazinul nostru. Parerea Utilizatorilor va fi publicata la feedback.trusted.ro. Informatiile sunt sterse automat de catre sistemul serviciului partener, dupa ce scopul a fost atins. Feedback.trusted.ro poate folosi terte sisteme pentru trimiterea tehnica a mesajelor, astfel incat sa se asigure ca acestea ajung la Utilizatori.

INREGISTRAREA CA UTILIZATOR

Pentru crearea unui cont pe site-ul secom.ro, Utilizatorul este obligat sa foloseasca o adresa de e-mail valida. Secom.ro poate refuza cererea de inregistrare in situatiile in care constata ca s-au utilizat informatii neconforme cu realitatea.  

PRETUL

Toate preturile produselor sunt exprimate in Lei, includ TVA si sunt finale. Preturile sunt cele de la data comandarii produselor, indiferent de eventualele modificari ulterioare ale preturilor. Pretul final platit de Utilizatoreste format din pretul produsului + cheltuielile de expeditie. Preturile de livrare sunt detaliate pe site.

Transportul pentru comenzile de peste 150 lei este suportat de catre secom.ro (se va lua in calcul suma efectiv de achitat de catre Utilizator, indiferent de eventualele discounturi existente in comanda in urma unor promotii sau a utilizarii de vouchere, coduri promotionale sau alte mecanisme de fidelizare). In cazul comenzilor cu o valoare mai mica de 150 lei tariful unic de transport este de 15 lei, fiind achitat de catre Utilizator.


COMANDA

Pentru a activa serviciul, Utilizatorul trebuie sa seteze detaliile preferate de Comanda (unde sa fie livrata, modalitatea de expediere si modalitatea de plata) in contul de Utilizator, iar aceste setari vor fi retinute si folosite pentru fiecare Comanda. Aceste setari pot fi modificate oricand, ele devenind operabile incepand cu urmatoarea comanda plasata. Prin finalizarea Comenzii Utilizator garanteaza ca toate datele furnizate sunt reale si corecte, in caz contrar putand suporta consecintele acestor erori (comanda intarziata, transmisa gresit etc).

Secom.ro nu poate fi tras la raspundere pentru informatii introduse eronat, din care pot decurge intarzieri de livrare. In acest context, toate taxele de transport efectuat in vederea reexpedierii Comenzii vor cadea in sarcina Utilizatorului.

Prin finalizarea Comenzii, Utilizatorul se declara de acord ca un reprezentant Secom® sa il poata contacta, prin orice mijloc disponibil, convenit de catre Parti (e-mail/telefon) pentru confirmarea personala a Comenzii.

Conform Legii nr. 365/2002 privind comertul electronic, articolul 9, contractul Utilizatorului referitor la achizitionarea Produselor mentionate in Comanda nu este incheiat complet pana cand secom.ro nu trimite un e-mail/notificare in cont care Utilizatorul este anuntat ca Produsele au fost expediate sau Produsele sunt pregatite pentru a fi ridicate (in cazul comenzilor cu ridicare din magazinele Secom®).

Secom.ro poate refuza o Comanda in urma unei notificari prealabile adresate Utilizatorului, fara a exista obligatii intre parti si fara ca o parte sa poata pretinde daune, pentru urmatoarele situatii:
  - esuarea / invalidarea tranzactiei online;
  - neacceptarea de catre banca emitenta a cardului Utilizatorului / a tranzactiei;
  - date incomplete sau incorecte ale Utilizatorului;
  - activitatea Utilizatorului poate produce daune site-ului secom.ro /partenerilor;
  - livrari consecutiv esuate;
  - alte motive obiective (spre exemplu, dar fara a se limita la: cazul in care Utilizator nu garanteaza ca modul de plata este valid si ca nu este obtinut printr-o metoda frauduloasa,exista suspiciuni cu privire la modalitatea de plata etc.).

Chiar daca a luat toate masurile pentru ca informatia prezentata pe acest site sa fie exacta si corecta, secom.ro nu poate fi raspunzatoare de inexactitati care pot aparea in completarea de catre Utilizator a formularelor de pe site pentru finalizarea Comenzii. Utilizatorii sunt responsabili pentru evaluarea exactitatii, completitudinii si utilitatii informatiilor furnizate in cadrul formularelor disponibile pe acest site.

FACTURARE – PLATI

Pretul si modalitatea de plata sunt specificate in Comanda. Secom® va emite catre Utilizatoro factura pentru Produsele si Serviciile livrate, obligatia Utilizatorlui fiind sa furnizeze toate informatiile necesare emiterii facturii conform cu legislatia in vigoare. 

MODALITATI DE PLATA

Plata in numerar se face in lei, integral, la livrare sau la ridicarea produselor din magazinele Secom®. In cazul coletelor livrate prin curier acesta va emite o chitanta. Pentru comenzile ridicate din magazinele Secom® se va emite bon fiscal.

Card de credit – Aceasta metoda de plata este complet securizata. Plata se poate face atat prin intermediul terminalelor POS existente in magazinele Secom®, cat si online.  Banca si procesatorul de plati (EuPlatesc.ro) sunt singurele institutii care intra in contact cu informatiile dumneavoastra, acestia oferind garantie totala. Daca tranzactia este autorizata, plata este facuta imediat, produsele achizitionate vor fi livrate in termen de cel mult 2 zile lucratoare din momentul confirmarii comenzii.

Online cu card bancar prin EuPlatesc (Visa/Maestro/Mastercard).

Daca ati ales metoda de plata " Online prin card bancar " este necesar sa completati un formular cu informatiile despre cardul dumneavoastra in pagina securizata a procesatorului de plati.

Platile cu carduri de credit/debit emise sub sigla Visa si MasterCard (Visa/Visa Electron si MasterCard/Maestro)  se efectueaza prin intermediul sistemului "3-D Secure" elaborat de organizatiile care asigura tranzactiilor on-line acelasi nivel de securitate ca cele realizate la bancomat sau in mediul fizic, la comerciant.

"3-D Secure" asigura in primul rand ca nici o informatie legata de cardul dumneavoastra nu este transferata sau stocata, la nici un moment de timp, pe serverele magazinului  sau pe serverele procesatorului de plati, aceste date fiind direct introduse in sistemele Visa si MasterCard.

Important de stiut! - Pentru platile prin card bancar nu este perceput nici un comision!


TERMEN DE VALABILITATE

Termenul de valabilitate este in conformitate cu datele mentionate pe etichetele Produselor, cu stricta respectare a conditiilor de depozitare/manipulare. Secom® este responsabil pentru toate viciile produsului prezente la data livrarii sau care apar in interiorul termenului de valabilitate. O sesizare trebuie facuta on-line, prin e-mail sau la telefon, la adresa sau nr. de telefon specificate in sectiunea „Contact” a prezentului site. Utilizatorlui ii va putea fi rambursata contravaloarea produsului intr-un cont bancar sau sub forma de voucher. Sesizarea trebuie sa contina descrierea defectului reclamat si o copie a facturii (sau orice alt act care sa demonstreze achizitia). Orice sesizare va fi procesata fara intarziere si Utilizatorva fi informat despre constatarile facute. Procedura de retur va fi trimisa pe e-mail Utilizatorlui, imediat ce a fost receptionata sesizarea. 


RETURUL IN CAZ DE VICII ASCUNSE SE ACORDA IN URMATOARELE SITUATII:

In situatia in care un produs aflat in perioada de valabilitate prezinta neconformitati ce fac nesigura sau imposibila administrarea/utilizarea sa, acesta va fi inlocuit;

Daca defectul prezentat nu poate fi inlaturat datorita naturii disproportionate a acestuia, si inlocuirea produsului nu este posibila, Utilizatorulva avea posibilitatea de a primi suma de bani intr-un cont bancar in RON;

In cazul altor defecte ce nu pot fi inlaturate, si care necesita inlocuirea produsului.

In functie de natura situatiei, dovada achizitiei produsului este suficienta si necesara.

Exclusiv, in cazul retururilor aferente produselor neconforme, conform prezentului articol, costurile de transport sunt in sarcina Secom®.

Pentru a beneficia de acestea, Utilizatorul are urmatoarele obligatii:

  - produsul sa fi fost cumparat de la secom.ro si folosit numai in scopul pentru care a fost conceput;
  - sa respecte instructiunile de folosire/depozitare/manipulare si sa utilizeze produsul conform instructiunilor impuse de producator;
  - sa pastreze documentul fiscal care sa ateste achizitia produsului;
  - sa pastreze ambalajul original si toate accesoriile care au venit in acesta, si sa le livreze impreuna cu produsul.

Cauze de neacceptare a returului:

  - deteriorari ale ambalajului;
  - expunere excesiva la radiatii solare;
  - lipsa sau modificarea informatiilor referitoare la lotul si termenul de valabilitate aferente produsului;
  - deteriorarile produse ca urmare a nerespectarii instructiunilor de folosire / depozitare / manipulare.

Toate celelalte prevederi cuprinse in legislatia in vigoare, referitoare la acoperirea de catre secom.ro a viciilor ascunse ale produselor vandute, sunt valabile. Drepturile Utilizatorilor sunt cele prevazute in legislatia in domeniu, acestea neextinzandu-se asupra eventualelor daune cauzate de folosirea produsului.

PRODUSELE COSMETICE

In conformitate cu legislatia uniformizata a Uniunii Europene, toate produsele cosmetice au aplicata pe ambalaj o pictograma (un desen ce reprezinta o cutie rotunda desfacuta si un numar). Numarul trecut pe aceasta pictograma reprezinta numarul de luni al termenului de valabilitate al produsului din momentul in care acesta a fost deschis.

LIVRAREA

Pentru produsele aflate in stoc, livrarea se va face prin Curier (FAN Courier, Cargus sau Sameday) si se aloca automat pe comanda, fara posibilitate alegerii optiunii de livrare printr-o firma de curierat anume.

De regula, livrarea in Bucuresti si in tara se face in termen de 24-48 de ore. In cazuri exceptionale (perioade de sarbatori si conditii meteo nefavorabile) timpul de livrare se poate mari, acest lucru fiind anuntat pe site-ul secom.ro.

Atentie! Din cauza timpului foarte scurt de procesare a Comenzilor, este imposibila operarea de modificari asupra Comenzilor deja confirmate.

In cazul in care produsele sunt indisponibile (inclusiv in cazul in care pretul sau unele caracteristici ale produselor au suferit modificari ulterioare confirmarii Comenzii), Secom® va informa Utilizatorul despre indisponibilitate, iar sumele pe care acesta le-a achitat online vor fi rambursate in termen de 30 de zile de la data confirmarii. Utilizatorul poate, la libera sa alegere, sa confirme modificarile intervenite cu privire la pretul sau caracteristicile produselor, modificand astfel Comanda initiala, iar Secom® va livra produsele conform Comenzii astfel modificate.


1. Conditii de livrare

La solicitarea secom.ro sau a Curierului, Utilizatorul va face dovada identitatii sale, daca e necesar, prin unul sau mai multe acte emise de autoritati oficiale si va comunica numarul de Comanda atribuit de catre Secom®.

In cazul in care rUtilizatorul se afla in imposibilitatea receptionarii Comenzii in mod personal, Comanda va fi lasata la adresa precizata doar unei persoane cu varsta mai mare de 18 ani si doar in cazul comunicarii catre Curier, a numarului Comenzii.

Nicio cerere de livrare a unei Comenzi nu va putea fi onorata in cazul in care Utilizatorul nu indeplineste conditiile de mai sus.

In cazul livrarii Comenzii (inclusiv la locul de munca al rUtilizatorului), si in situatia in care Curierul nu poate avea acces in locatia respectiva, Utilizatorul trebuie sa se asigure ca poate receptiona aceasta Comanda.

Conditiile de livrare se completeaza cu Termenii si Conditiile companiei de curierat agreate, acestea fiind disponibile pe site-urile partenerilor (FAN Courier, Cargus sau Sameday).

Livrarea se considera a fi indeplinita de catre secom.ro, la momentul predarii produselor Comandate catre Utilizator.


2. Receptia produselor

Utilizatorul se obliga sa semneze bonul de livrare (AWB) prezentat de catre Curier la livrarea produselor comandate. De asemenea, Utilizatorul va verifica daca produsele sunt deteriorate sau daca exista lipsuri. Prin semnarea bonului de livrare Utilizatorul recunoaste primirea in bune conditii si in totalitate a produselor Comandate.

CAMPANIILE PROMOTIONALE

1. Codurile promotionale / cupoane discount / vouchere

Codurile promotionale oferite de secom.ro prin orice promotii sunt valabile exclusiv pentru sectiunea mentionata in e-mailul de transmitere a codului, cu exceptia cazului in care este specificat altfel. Codurile promotionale sunt valabile exclusiv pentru comenzile online, indiferent de modalitatea de plata si de livrare alese. De mentionat este faptul ca unele produse sunt incadrate in categoria "Exceptii", produse asupra carora nu se pot aplica coduri promotionale, ci se achizitioneaza la pretul afisat pe site. Codurile promotionale pot fi folosite exclusiv in momentul plasarii Comenzii, acestea nu pot fi folosite pentru a reduce valoarea Comenzii dupa ce aceasta a fost plasata.

Intr-o Comanda se poate folosi un singur cod promotional, pagina de Comanda nu permite utilizarea mai multor coduri pentru aceeasi Comanda. Pentru eligibilitatea unei promotii nu este luata in calcul valoarea transportului, cu exceptia cazului in care este specificat altfel.

In cazul in care un cod promotional a fost aplicat intr-o Comanda care nu a putut fi onorata sau a fost anulata din orice motiv, Utilizatorul are dreptul de a cere reactivarea codului promotional exclusiv in scris, respectiv printr-un e-mail trimis pe adresa info@secom.ro, care sa contina numarul Comenzii, starea acesteia, numele Utilizatorului si codul promotional. Acesta se reactiveaza in aceleasi conditii ca si cele ale codului promotional initial.

Atentie! Promotiile prezentate pe secom.ro nu se cumuleaza, se aplica reducerea cea mai mare sau pentru cea pe care a ales-o Utilizatorul. Acest lucru este valabil pentru orice forma de reducere (carduri de fidelitate, coduri promotionale, vouchere-cadou, reduceri procentuale de pe site etc.) ca regula, cu exceptia situatiilor in care este mentionat altfel in regulamentul promotiei. De asemenea, in cazul existentei unor vouchere procentuale, detinatorul unui asemenea voucher nu poate beneficia de reducerea cumulata conferita de aplicarea voucherului procentual la reducerea deja existenta in cadrul unei promotii.

Taxele de transport si manipulare se aplica oricarui produs achizitionat, indiferent de valoare daca in promotii, regulamente etc. nu se prevede altfel. Un cod promotional nu se poate utiliza pentru a plati taxele de transport cu exceptia cazului in care regulamentul promotiei specifica acest lucru.

Orice comanda, indiferent de promotie, va avea o valoare minima. Secom® nu poate onora comenzi cu valoare zero.

Secom® stabileste unilateral regulamentele promotiilor si ale concursurilor pe care le organizeaza pe secom.ro, acestea fiind publicate exclusiv pe site. Promotiile sunt aplicate Comenzilor care respecta integral regulile afisate pe site, in perioada de valabilitate clar mentionata si in limita stocului disponibil. Secom.ro nu garanteaza disponibilitatea pe stoc a produselor pentru intreaga perioada de promotie si poate retrage promotia fara notificare prealabila.

Toate prevederile referitoare la frauda, mentionate pe site-ul www.secom.ro sunt pe deplin aplicabile in ceea ce priveste fraudarea legata de codurile promotionale iar tentativa de frauda va fi adusa la cunostinta organelor abilitate.

2. Intreruperea campaniei promotionale

Organizatorul isi rezerva dreptul de a modifica oricare din conditiile stabilite in regulamentul campaniei respective, in perioada desfasurarii campaniei, dar nu inainte de a anunta publicul despre aceste modificari.

2.2 Conditii de aplicare

Orice promotie la care se aplica coduri promotionale se limiteaza la un singur cod promotional per persoana fizica sau persoana juridica (identificat ca si client / utilizator / user).

Fiecare cod promotional este limitat la o singura utilizare si are valabilitate pentru o perioada limitata de timp, astfel cum va fi mentionat in termenii si conditiile aferente, in mailul de oferire a codului promotional si in contul de utilizator sau cum se mentioneaza in fiecare promotie/oferta in parte. Secom® isi rezerva dreptul de a modifica sau anula aceste coduri promotionale in orice moment, cu anuntarea prealabila a Utilizatorilor.

Niciun cod promotional nu poate fi aplicat altor promotii/oferte cu exceptia celor strict mentionate in oferta respectiva.

Pentru retururi integrale de produse codul promotional nu poate fi aplicabil. In cazul unui retur partial, codul promotional ramane valabil doar daca produsele pastrate in comanda sunt eligibile conform regulamentului promotiei, acestea avand efecte numai ca reducere aplicata acelor produse efectiv platite.

Voucherul se aplica numai produselor/categoriilor mentionate in mod expres ca fiind eligibile in promotie. Ofertele promotionale nu se pot cumula in aceeasi comanda. Doar un singur cod promotional de reducere se poate folosi per comanda.

Orice cod promotional va fi valabil incepand cu data aplicarii acestuia ca reducere la comanda deja efectuata. Un cod promotional nu se poate aplica pe o comanda plasata in trecut.

2.3 Anulare, refuz, retur comanda

In cazul returnarii produselor achizitionate la care s-a aplicat si un cod promotional, discountul acordat va putea fi retinut din valoarea returului.

In cazul in care o comanda la care s-a aplicat reducere prin cod promotional este anulata, respinsa sau returnata integral, Utilizatorului i se va returna doar suma efectiv achitata.

3. Campanii cu produs cadou

In cazul in care o comanda la care s-a oferit produs cadou este respinsa sau returnata integral, Utilizatorul este obligat sa returneze si produsul cadou in starea lui originala, nedeteriorata si sigilat. Conditiile integrale de retur se pot gasi la punctul 17 - Politica de retur.

PROGRAMUL DE FIDELIZARE UTILIZATORI
Regulamentul oficial al Programului de Fidelitate Secom
Acest program de fidelitate este organizat și desfășurat de Secom Healthcare S.R.L., având următoarele date de identificare: Cod Unic
de Inregistrare RO 8978457, număr de înregistrare la Registrul Comertului J40/9299/1991, sediul social in Cladirea Equilibrium 1, Str.
Gara Herăstrau nr. 2, etaj 8, Sector 2, București, România Tel.: 021 9962; e-mail: info@secom.ro, denumită în continuare „Secom”.
Participanții sunt obligați să respecte termenii și condițiile Regulamentului Oficial al Programului de Fidelitate (denumit in continuare
„Regulamentul Oficial”), potrivit celor menționate mai jos.
Regulamentul Oficial sta la dispoziția oricărui Participant la Programul de Fidelitate, pentru consultare, în oricare dintre magazinele
fizice Secom (denumite in continuare „magazinele Secom” iar lista acestora se găsește pe secom.ro /
https://www.secom.ro/pages/magazine-secom ), precum și pe site-ul www.secom.ro.
1. Condiții de participare la Programul de Fidelitate
1.1 Orice persoana fizica în vârsta de cel puțin 18 ani poate intra în posesia cardului de fidelitate Secom, devenind astfel membru al
Programului de fidelitate („Programul”) și beneficiar al avantajelor acordate în baza Cardului de Fidelitate la tranzacțiile
efectuate.
1.2 Participanții beneficiază de înscriere gratuită în Programul de Fidelitate Secom. Există mai multe modalități de a te alătura
Programului de Fidelitate Secom:
- Pe www.secom.ro, in pagina dedicata Programului de Fidelitate Secom sau in cosul
cosul de cumparaturi, inainte de finalizarea comenzii, în sectiunea „Card de Fidelitate”;
- In toate magazinele Secom, inaintea emiterii bonului fiscal.
1.3 Pentru înscrierea în Programul de Fidelitate Secom, Participantul trebuite să furnizeze toate datele necesare pentru completarea
integrală a câmpurilor marcate ca obligatorii în Formularul de înscriere în Programul de Fidelitate.
Câmpurile obligatorii a fi completate sunt: nume, prenume, data nașterii, adresa de e-mail si număr de telefon.
Câmpurile opționale a fi completate sunt: , răspunsul la întrebarea: „Aveți copii sub 12 ani?”, Oraș/Județ.
1.4 Câmpurile Formularului de înscriere vor fi completate cu datele reale și exacte ale Participantului care dorește să intre în
Programul de Fidelitate Secom.
2. Beneficiile Programului de Fidelitate Secom
2.1 Programul de Fidelitate Secom oferă posibilitatea Participantului de a beneficia de acumularea de puncte de loialitate intr-un cont
de uilizator, în functie de valoarea cumulată a achizițiilor realizate atât în magazinele Secom, cât și online pe site-ul
www.secom.ro, începând cu momentul includerii Participantului în Programul de Fidelitate. Acumularea punctelor se realizeaza ,
pentru orice fel de achizitii atat pentruproduse care fac obiectul unei campanii promotionale, cat si pentru acele categorii de
produse care nu fac obiectul unei campanii, mai exact pentru produsele achizitionate la pret intreg.
2.2 Fiecare punct acumulat este echivalent cu 1 leu (1 punct = 1 leu).
2.3 La fiecare achizitie Participantul acumuleaza puncte de loialitate in functie de clasa de loialitate din care face parte. Punctele pot fi
cheltuite pentru achizitii viitoare (scazute din valoarea facturii/bonului).
2.4 Folosirea punctelor acumulate se realizeaza exclusiv pentru achizitii cu pret intreg in magazinele fizice Secom, cat si
www.secom.ro, atat timp cat posesorul cardului de fidelitate utilizeaza aceeasi adresa de e-mail ca la inscrierea in program.
2.5 Pentru a intra in Programul de Fidelitate, Participantul trebuie sa solicite un card de fidelitate (pe site-ul secom.ro sau in
magazinele Secom). Daca cardul este solicitat pe secom.ro, cardul va fi virtual si poate fi folosit si in magazinele Secom. Clientii
care solicita card de loialitate in magazinele Secom vor primi un card fizic cu o serie unica de identificare.
2.6 Programul va fi valabil atat pe secom.ro cat si in magazinele Secom.
2.7 La inscriere in program, Participantul este automat inscris in clasa Silver.
2.8 Odată inclus în Programul de Fidelitate, Participantul beneficiază de anumite clase ("Clase”) in funcție de valoarea acumulata
astfel:
● In clasa Silver primeste puncte in valoare de 5% raportat la suma achitata aferenta bonului fiscal/comenzii online;
● In clasa Gold primeste puncte in valoare de 7% raportat la suma achitata aferenta bonului fiscal/comenzii online;
● In clasa Platinum primeste puncte in valoare de 10% raportat la suma achitata aferenta bonului fiscal/comenzii online;
Exemplu: Pentru un bon fiscal/comanda online in valoare de 100 lei:
o un client Silver primeste 5% din valoarea achizitiei, adica 5 puncte = 5 lei, pe care ii poate folosi in cadrul unei achizitii viitoare, doar
pentru produse cu pret intreg care nu fac obiectul unei campanii promotionale;
o un client Gold primeste 7% din valoarea achizitiei, adica 7 puncte = 7 lei, pe care ii poate folosi in cadrul unei achizitii viitoare, doar pentru
produse cu pret intreg care nu fac obiectul unei campanii promotionale;
o un client Platinum primeste 10% din valoarea achizitiei, adica 10 puncte = 10 lei, pe care ii poate folosi in cadrul unei achizitii viitoare,
doar pentru produse cu pret intreg care nu fac obiectul unei campanii promotionale;
● campanii/promoții speciale adresate deținătorilor de card de Fidelitate (ex: campanii de reduceri, transport gratuit pentru
membrii Programului de Fidelitate, promotii personalizate, pachete promotionale 1 + al doilea la 50% reducere, pachete
avantajoase cu 25% reducere, dublarea punctelor acumulate, puncte aniversare in programul de loializare sau la aniversarea
zilei de nastere).
2.9 La atingerea oricărui nou prag de achiziții, noul sistem de acordare al punctelor se va aplica în termen de 24 ore de la atingerea
acestuia, conform clasei din care face parte Participantul.
2.10 Participantul este incadrat in una din cele 3 clase, in functie de valoarea cumulata in decursul a 12 luni calendaristice dupa cum
urmeaza:
● In clasa Silver achizitii cuprinse intre 0 – 1,200 lei/anual;
● In clasa Gold achizitii cuprinse intre 1,201 – 2,300 lei/anual;
● In clasa Platinum achizitii peste 2,301 lei/anual;
2.11Beneficiul clasei (Silver, Gold, Platinum) rămâne același, până la momentul în care se atinge urmatorul prag. In calculul valorii
cumulate a achizițiilor sunt incluse costurile de transport aferente comenzilor online.
2.12Beneficiul clasei poate fi pierdut daca achizitiile anuale nu se incadreaza conform Art. 2.10. Daca Participantul nu atinge valorile
din Art. 2.10 acesta va trece in clasa aferenta achizitiilor realizate in ultimele 12 luni dar nu mai jos de clasa Silver.
2.13Beneficiul mentionat la Art. 2.8 se acorda pe loc, la orice tranzacție efectuată pe site-ul www.secom.ro sau în oricare din
magazinele Secom și se reflectă în contul de utilizator al Participantului, indiferent de modalitatea de plată (cash, card).
2.14Pentru fiecare achizitie valabilitatea punctelor este de 12 luni calendaristice incepand cu data de 1 ale lunii viitoare pentru fiecare
achizitie in parte (Exemplu: achizitie in data de 10 martie 2023, valabilitatea punctelor aferente tranzactiei din ziua respectiva, va fi valabila
in intervalul 10 martie 2023 – 31 martie 2024. Daca urmatoarea achizitie are loc pe data de 10 aprilie 2023, valabilitatea punctelor aferente
tranzactiei din 10 aprilie 2023, va fi valabila in intervalul 10 aprilie 2023 – 30 aprilie 2024).
2.15Punctele acumulate aferente Programului de Fidelitate Secom nu se cumulează cu punctele mai multor posesori de card pentru
aceeasi achizitie pe bon fiscal/comanda online.
2.16In cazul retururilor parțiale sau integrale de comenzi/bonuri fiscale, sumele returnate Participantului vor fi luate în calcul la
stabilirea clasei aferente Participantului, acest lucru putând genera schimbarea clasei de acordare a punctelor conform Art. 2.8
respectivului Participant.
2.17In cazul retururilor parțiale sau integrale de comenzi/bonuri fiscale, sumele returnate Participantului vor fi luate în calcul la
stabilirea clasei aferente Participantului, acest lucru putând genera schimbarea clasei de acordare a punctelor conform Art. 2.8
respectivului Participant. Totodata daca sumele returnate Participantului nu ii modifica clasa din care face parte, conform Art. 2.10
, Participantului i se reduc din contul de utilizator doar punctele aferente tranzactiei returnate.
3. Cardul de fidelitate Secom
3.1 Programului de Fidelitate Secom îi este asociat Cardul de Fidelitate Secom. Acesta oferă Participantului posibilitatea de avea
aceleași beneficii asociate contului de utilizator www.secom.ro și în magazinele Secom. In același timp, pe baza Cardului de
Fidelitate, achizițiile din magazinele Secom vor putea fi cumulate cu achizițiile online prin site-ul www.secom.ro, acestea intrând
în calculul valorii cumulate a achizițiilor ce determină clasele (Silver, Gold, Platinum) Programului de Fidelitate Secom, atata
vreme cat in momentul tranzactiei Participantul utilizeaza acelasi Card de Fidelitate
3.2 Dupa primirea mailului de confirmare a fidelizării contului www.secom.ro, Participantul va primi un Card virtual de Fidelitate
Secom.. De asemenea, un Participant deja înscris în Programul de Fidelitate Secom și posesor al unui card de fidelitate fizic obținut
în unul din magazinele Secom, are posibilitatea asocierii acestui card la contul de utilizator creat pe www.secom.ro, prin
introducerea numărului de telefon atribuit Cardului de Fidelitate fizic si a codului unic de identificare inscris pe Cardul de
Fidelitate în sectiunea Card de Fidelitate din contul online al Participantului, beneficiile deja asociate Cardului de fidelitate fiind
automat importate și asociate contului online.
3.3 Benficiile aferente Cardului de fidelitate sunt valabile în toate magazinele Secom®
.
3.4 Cardul de Fidelitate este unic, personal și netransmisibil.
4. Folosirea Cardului de Fidelitate Secom
4.1 Pentru a accesa beneficiile aferente Cardului de Fidelitate în cadrul magazinelor Secom, Participantul va trebui să anunțe că este
Posesor de Card de Fidelitate înainte ca personalul din magazin să înceapa introducerea produselor în casa de marcat fiscală și să
prezinte Cardul de Fidelitate la casa de marcat sau să indice numărul de telefon înregistrat la realizarea cardului. Cardul va fi
înregistrat, moment în care personalul Secom va avea acces la informațiile Cardului de Fidelitate și beneficiileaferente. Sistemul
este centralizat pentru toate magazinele Secom și pentru site-ul www.secom.ro.
4.2 In magazinele Secom, Participantul are acces la beneficiile aferente Cardului de Fidelitate ca urmare a furnizării numarului de
telefon aferent Cardului de Fidelitate, în situatiile în care Participantul nu deține Cardul de Fidelitate fizic la acel moment. In cazul
în care Participantul nu deține Cardul de Fidelitate și nu furnizează numărul de telefon corespunzător Cardului de Fidelitate, nu
poateaccesa beneficiile Cardului de Fidelitate.
4.3 Pentru acces la beneficiile acordate prin Cardul de Fidelitate, la cumpăraturile realizate online pe site-ul www.secom.ro,
Participantul trebuie să fie inregistrat în site-ul www.secom.ro, cu o adresa validă de e-mail. Fidelizarea contului de utilizator se
va face automat, in decurs de 24 ore din momentul atingerii claselor Programului de Fidelitate Participantul nu trebuie să facă
nicio cerere în acest sens. Imediat ce contul a fost fidelizat, Participantul va primi o înștiințare în acest sens prin e-mail. Pentru a
avea acces la beneficiile Programului de fidelitate, Participantul trebuie să se logheze în site în contul dedicat. Dupa logare în cont,
Participantul va putea vizualiza direct în contul de utilizator valoarea punctelor acumulate, precum si valabilitatea acestora în
funcție de clasa din care face parte.
5. Cardurile deteriorate, pierdute, furate sau nefolosite
5.1 In cazul în care un Card de Fidelitate Secom s-a deteriorat, a fost furat sau pierdut, trebuie anunțat imediat sunând la 021 316 43 93
sau trimitand un email la info@secom.ro și comunicând numele, adresa și datele de identificare ale posesorului de card.
Participantul care deteriorează, își pierde cardul sau îi este furat are dreptul la un nou card.
5.2 In cazul Cardurilor de Fidelitate neasociate unui cont de utilizator pe site-ul www.secom.ro, în perioada de preschimbare a
cardului nu se vor contoriza valorile de achiziție pentru trecerea la o noua Clasa.. Pentru a beneficia de continuitate în cumularea
valorii comenzilor / acordarea beneficiilor conform Clasei din care face parte, recomandăm asocierea cardului de fidelitate cu un
cont de utilizator pe site-ul www.secom.ro. Asocierea cardului cu un cont de utilizator pe www.secom.ro se poate face în
momentul crearii unui nou cont pe site-ul www.secom.ro, dar și ulterior prin intermediul meniului asociat contului de utilizator.
5.3 Pentru cardurile de fidelitate asociate unui cont de utilizator www.secom.ro, în perioada de preschimbare a cardului, doar
achizițiile online se vor putea contoriza pentru trecerea la o noua clasa, Silver, Gold, Platinum
5.4 In cazul Cardurilor de Fidelitate fizice, acestea se vor emite în termen de maxim 15 zile lucrătoare de la comunicarea deteriorarii,
pierderii sau furtului.
5.5 In cazul înlocuirii Cardului de Fidelitate, beneficiile asociate cardului de fidelitate anterior se păstreaza.
5.6 In cazul în care Cardul de Fidelitate nu va fi utilizat timp de 2 (doi)ani consecutivi de către posesorul de card, acesta va deveni
inactiv și nu va mai putea fi utilizat. In cazul în care a intervenit invalidarea unui Card de Fidelitate, Secom va șterge din baza sa
de date toate datele cu caracter personal ale respectivului deținător de card.
5.7 In situația în care Cardul de Fidelitate este inactiv, urmare a neutilizării acestuia, iar persoana dorește totuși să aibă acces la
beneficiile Cardului de Fidelitate, va trebui sa completeze în magazinele Secom formularul de înscriere în vederea eliberării unui
nou Card de fidelitate sau pe site-ul www.secom.ro, în sectiunea Card de Fidelitate. In acest caz, Participantul nu va putea accesa
beneficiile acumulate în trecut în baza cardului de fidelitate anterior.
5.8 O data pe an, Secom va proceda la verificarea cardurilor în vederea invalidarii acelor carduri de fidelitate care nu au fost utilizate
timp de 2 (doi) ani consecutivi.
5.9 Falsificarea Cardului de Fidelitate determină retragerea beneficiilor acordate în baza sa, precum și dreptul Secom de a se îndrepta
împotriva celui ce a utilizat cardul falsificat pentru recuperarea tuturor prejudiciilor și a solicita pedepsirea conform legilor în
vigoare.

6. Valabilitatea cardului

6.1 Secom își rezerva dreptul de a invalida cardurile de fidelitate care nu au fost deloc utilizate timp de 2(doi) ani consecutiv sau acele
carduri care au fost utilizate cu încalcarea dispozițiilor prezentului Regulament sau ale prevederilor legale.
6.2 Invalidarea cardurilor de fidelitate presupune ștergerea de către Secom a tuturor datelor cu caracter personal aferente
respectivelor carduri care nu au fost utilizate timp de 2 (doi) ani consecutiv.
7. Durata programului de fidelitate
7.1 Programul de fidelitate are durata nedeterminată. Secom poate hotări în orice moment încetarea programului de fidelitate.
Inștiințarea referitoare la încetarea Programului va fi realizată cu cel puțin 15 zile înainte de data încetării Programului de
Fidelitate în toate magazinele Secom și pe www.secom.ro.
8. Modificarea Regulamentului Oficial al Programului de Fidelitate Secom
8.1 Secom își rezerva dreptul de a modifica sau schimba prezentul Regulament Oficial și se angajează ca înaintea operării unor astfel
de modificari să le faca publice prin intermediul site-ului www.secom.ro, în magazinele Secom și prin orice alte canale de
comunicare pe care le considera oportune.
9. Protectia datelor cu caracter personal Conform Regulamentului european nr. 679/2016
9.1 Prezentul Regulament Oficial stă în mod gratuit la dispoziția oricărei persoane sau autorități interesate, putând fi consultat în
magazinele Secom și pe site-ul www.secom.ro. Participarea la Programul de Fidelitate implica obligativitatea respectării
prevederilor prezentului Regulament Oficial.
9.2 Secom, în calitate de operator autorizat, prelucreaza datele cu caracter personal furnizate prin completarea formularului de
înscriere în Programul de Fidelitate. Prin participarea la Programul de Fidelitate, Participanții consimt în mod expres, în mod liber
și în cunoștința de cauza că Secom va colecta, stoca, extrage, consulta, utiliza datele cu caracter personal înscrise în Formularul de
înscriere în Programul de Fidelitate. Scopul efectuării prelucrării categoriilor de nume, prenume, data nașterii, adresa de mail si numar
de telefon este eliberarea cardului de fidelitate și acordarea reducerilor aferente.
9.3 Scopul prelucrării datelor privind numărul de telefon și adresa de mail este de a informa Participantul despre noutățile apărute,
campanii în desfășurare, oferte și reduceri personalizate. De asemenea, în situația în care Participantul completează câmpul
aferent intrebării „Aveți copii sub 12 ani?”, datele vor fi prelucrate pentru furnizarea unor oferte personalizate.
9.4 Numarul de telefon si adresa de e-mail sunt elemente de identificare ale clientului in Program. In cazul in care acestea se vor
modifica clinetul poate face modificare in contul sau sau in magazinele SECOM.
9.5 Datele privind istoricul de cumpăraturi vor fi prelucrate prin intermediul deciziilor automate în interesul legitim al Secom de a
îmbunatăți calitatea relației cu clienții săi prin prezentarea de oferte personalizate care sa corespundă comportamentului de
cumpărare al acestora.
9.6 In cazul în care Participantul și-a exprimat consimțământul prin completarea Formularului de acord/actualizarea datelor sale
pentru ca numărul de telefon și/sau adresa sa de email să fie prelucrate și în scop de marketing direct, Secom va prelucra aceste
date în vederea transmiterii de materiale și informații cu privire la campanii promoționale/oferte personalizate clienților fideli,
prin email sau SMS.
9.7 Personalizarea ofertelor va fi realizată pe baza istoricului cumpăraturilor efectuate din magazinele Secom și/sau de pe site-ul
www.secom.ro. Secom va pastra istoricul cumpărăturilor efectuate pentru o perioada de 2 (doi) ani. O data pe an, Secom va
proceda la anonimizarea cumpărăturilor efectuate mai vechi de 2 (doi) ani.
9.8 Secom va înceta prelucrarea datelor personale în scopul acordării beneficiilor în baza cardului de fidelitate, de îndată ce persoana
își va retrage consimțământul acordat pentru utilizarea datelor sale în acest scop. In cazul in care clientul isi retrage
consimțământul acordat pentru utilizarea datelor in scopul emiterii cardului acesta va pierde beneficiile Programului si nu va mai
putea acumula puncte.
9.9 Secom va înceta prelucrarea datelor personale în scop de marketing de îndată ce persoana își va retrage consimțământul acordat
pentru utilizarea datelor sale în acest scop sau se va opune prelucrării datelor sale în scopul de a primi oferte personalizate.
9.10Pentru dezabonarea de la primirea prin SMS a materialelor și informațiilor cu privire la campanii promoționale/oferte,
Participantul poate sa își retragă consimțământul astfel:
- prin prezentarea în magazinele Secom și ștergerea bifei referitoare la consimțământ prin intermediul tabletei,
- prin apelarea Customer Care la numărul de telefon 021 9962,
- prin transmiterea unui e-mail pe adresa: dpo@secom.ro.
Dezabonarea de la primirea prin email a materialelor și informațiilor cu privire la campanii promoționale/oferte se va realiza prin
opțiunea „unsubscribe” disponibilă în cuprinsul email-ului transmis, sens în care Secom va șterge din baza sa de date adresa de
email a Participantului.
9.11Secom se obligă să respecte legislația privind protecția datelor cu caracter personal, să păstreze confidențialitatea datelor personale
ale Participantilor și sa le utilizeze numai în conformitate cu prezentul Regulament Oficial și legislația în vigoare.
Conform Regulamentului 679/2016, Participantții au următoarele drepturi:
- dreptul de informare (art. 13) care presupune informarea de o manieră concisă, transparenta și ușor accesibilă a persoanelor
vizate cu privire la datele prelucrate;
- acces (art. 15), prin intermediul căruia persoana vizată obține din partea operatorului o confirmare a faptului ca datele sale
personale sunt sau nu prelucrate;
- rectificare (art. 16) care presupune posibilitatea de a solicita operatorului să rectifice datele inexacte care privesc persoana
vizată;
- restricționarea prelucrării (art. 18) apare în situația unei prelucrări inexacte, ilegale sau a exercitării dreptului la opoziție de
către persoana vizată;
- portabilitatea datelor (art. 20) reprezintă dreptul de a primi datele cu caracter personal care o privesc și pe care le-a furnizat
operatorului într-un format structurat, utilizat în mod curent și care poate fi citit automat și care include și dreptul de a
transmite aceste date altui operator;
- opoziție (art. 21) presupune inclusiv dreptul de a se opune creării de profiluri;
- dreptul de a fi uitat (art. 17) reprezinta dreptul persoanei vizate de a obține ștergerea datelor sale de către operator, în anumite
condiții prevăzute în Regulament;
- dreptul de a nu face obiectul unei decizii bazate pe prelucrarea automata (art. 22);
- aveți, de asemenea, dreptul de a va adresa Autorității Naționale de Supraveghere a prelucrării datelor cu caracter personal.
9.12Participanții la Programul de fidelitate Secom își pot exercita oricând drepturile de mai sus, în scris, la adresa de corespondență:
Cladirea Equilibrium 1, Str. Gara Herastrau nr. 2, etaj 8, Sector 2, Bucuresti
9.13In cererea formulată, Participanții vor preciza adresa unde doresc să primească informațiile solicitate (poșta electronică, sau
printr-un serviciu de corespondență care să asigure că predarea li se va face numai personal). Pentru soluționarea eficientă a
cererilor adresate, Participanții vor descrie detaliat contextul în care au furnizat datele cu caracter personal către Secom.
Astfel, la cererea scrisă a Participantului, adresată Secom la adresa de mail dpo@secom.ro sau la adresa de corespondență (datată și
semnată), Secom se obligă să onoreze cererile Participanților, în măsura în care acestea nu sunt excesive, abuzive sau nejustificate.
10. Litigii
10.1Eventualele litigii apărute între Secom și Participanții la Programul de fidelitate Secom se vor rezolva pe cale amiabilă sau, în cazul
în care aceasta nu va fi posibilă, litigiile vor fi soluționate de instanțele judecătorești competente de la sediul Secom.


POLITICA DE RETUR SI GARANTIE

Conditii generale

Secom.ro ofera posibilitatea de retur pentru Utilizatorii care notifica intentia lor in termen de maxim 14 zile calendaristice de la primirea coletului sau oricand, pe parcursul termenului de valabilitate al produsului, daca sunt constatate neconformitati ale produsului ce fac nesigura sau imposibila administrarea/utilizarea sa).

Prezentele prevederi se completeaza cu prevederile articolului 12.

Cheltuielile de returnare a produsului vor fi suportate integral de catre Utilizator, exceptie facand retururile ocazionate de neconformitati/vici ascunse ale produsului, conform punctului 12. Returul in caz de vicii ascunse.

Produsele se pot returna prin orice companie de curierat care asigura livrarea coletului de retur la depozitul Secom®. Adresa la care se trimit coletele de retur este: KLG Europe Logistics SRL / Romania Autostrada Bucuresti – Pitesti, km. 23 Str. Industriilor nr.9 Bolintin Deal, Giurgiu Tel.: (+40) 21 40 84 040

Garantia legala de conformitate

Secom.ro ofera garantia legala de conformitate care reprezinta obligatia legala a vanzatorului fata de consumator ca, fara solicitarea unor costuri suplimentare, sa aduca produsul la conformitate, incluzand restituirea pretului platit de consumator, repararea sau inlocuirea produsului daca acesta nu corespunde conditiilor enuntate in declaratiile referitoare la garantie sau in publicitatea aferenta.

1. Modalitati si termene de returnare a produselor

Utilizatorul se obliga sa notifice secom.ro intentia sa de a returna produsele achizitionate prin e-mail la adresa: e-shop@secom.ro, sau sa efectueze returul fara notificare, in termen de maxim 14 zile calendaristice de la primirea produselor. In caz contrar, secom.ro poate refuza coletul.

Pentru notificarea prin e-mail, Utilizatorul va completa si trimite catre Secom® “Formularul de retur Produse” sau va face orice alta declaratie neechivoca in care isi exprima decizia de retragere din contract.

Catre: Secom Healthcare SRL, Cladirea Equilibrium 1, Str. Gara Herastrau nr. 2, etaj 8, Sector 2, Bucuresti, Tel: 021 9962, Email: e-shop@secom.ro

Va informez prin prezenta cu privire la retragerea mea din contractul referitor la vanzarea urmatoarelor produse:

  Produsele au fost comandate la data:
  Numarul comenzii:
  Numele cumparatorului:
  Adresa cumparatorului:
  Semnatura consumatorului (doar in cazul in care acest formular este notificat pe hartie):
  Data:
  Detalii motiv retur:

O alta modalitate de a returna produsele este de a completa formularul de retragere din contract, disponibil aici, pe care il puteti trimite la adresa de email e-shop@secom.ro.

2. Conditii de returnare a produselor

In orice situatie de returnare a produselor, acestea trebuie sa fie in aceeasi stare in care au fost receptionate de Utilizator, in ambalajul original, cu etichetele intacte si impreuna cu toate documentele care l-au insotit (factura, bon fiscal etc.). Produsele returnate trebuie sa fie in aceeasi conditie ca la primire: fara defecte (cu exceptia viciilor ascunse si exceptand defectele deja semnalate de Utilizatorin corespondenta cu secom.ro).

In urma analizarii Produselor returnate, precum si a respectarii conditiilor de retur, secom.ro poate accepta/refuza returul. In cazul refuzului, secom.ro va comunica Utilizatorului motivele acestuia. Totodata, expedierea catre Utilizator a produselor neacceptate ca retur se va face exclusiv pe cheltuiala si la initiativa Utilizatorului. In cazul in care, in termen de cel mult 60 de zile de la data comunicarii refuzului de preluare a returului, Utilizatorul nu intreprinde niciun demers pentru ridicarea Produsului, Secom® va proceda la distrugerea acestuia, putand pune la dispozitia Utilizatorului documente doveditoare in acest sens.

Conform art. 16 lit. d) si e) din O.U.G. nr. 34/2014 Secom® isi rezerva dreptul de a nu accepta returnarea produselor care care sunt susceptibile a se deteriora sau a expira rapid ori care nu pot fi returnate din motive de protectie a sanatatii sau din motive de igiena si care au fost desigilate de consumator (Produsul poate fi returnat numai daca este in conditii perfecte, inclusiv ambalajul, adica sa nu prezinte sub nici o forma semne de uzura sau consum, sa fie nedesfacut, respectiv sigilat).

In cazul in care o comanda la care s-a oferit produs cadou este returnata integral, Utilizatorul este obligat sa returneze si produsul cadou in starea lui originala, nedeteriorat si sigilat. Conditiile campaniilor promotionale se pot citi la punctul 13. “Campaniile Promotionale”, mai exact punctul 13.4 “Campanii cu produs cadou”.

Produsele ce constituie un set trebuie returnate ca set.

Intrucat produsele comercializate de catre Secom® sunt obtinute din materii prime naturale, nu se vor accepta returi pe considerente de diferente/discrepante de culoare/gust/miros/textura intre acelasi tip de produs provenind din loturi diferite/lot similar, acestea nereprezentand o lipsa de conformitate/viciu ascuns.

3. Termene de returnare a sumelor achitate

Pentru retururi integrale de comenzi, rUtilizatorul primeste banii aferenti produselor returnate si costul transportului achitat initial (daca e cazul) in interval de 14 zile calendaristice de la data la care secom.ro accepta returul.

Pentru retururi partiale de comenzi, daca valoarea produselor ramase in comanda nu respecta conditiile ofertei/promotiei referitoare la transportul gratuit, mai precis valoarea lor cumulata nu mai depaseste 150 lei, Utilizatorul primeste banii aferenti produselor returnate mai putin costul unui transport aferent produselor pastrate (produsele pastrate sunt asimilate unei comenzi care nu se califica pentru transport gratuit).

MODALITATILE DE ADRESARE A RECLAMATIEI

Reclamatia reprezinta posibilitatea oferita Utilizatorului ca, in conformitate cu normele legale existente, sa inainteze o solicitare de rezolvare a unor situatii considerate de catre acesta drept abateri de la conditiile legale sau contractuale de furnizare a produselor si serviciilor, la care se asteapta un raspuns sau o rezolutie.

Utilizatorii care beneficiaza de serviciile Secom® pot adresa reclamatiile in limba romana astfel:
  - prin posta, catre Secom Healthcare SRL, Cladirea Equilibrium 1, Str. Gara Herastrau nr. 2, etaj 8, Sector 2, Bucuresti;
  - prin e-mail la adresa e-shop@secom.ro, info@secom.ro sau pe site prin formularul de contact: https://www.secom.ro/contact

Reclamatiile vor fi receptionate si procesate de Luni pana Vineri, in intervalul orar 09:00-17:00.

Reclamatia poate fi adresata exclusiv in forma scrisa. In situatia expedierii prin posta, aceasta se va face doar confirmare de primire.

Sesizarea trebuie adresata/inaintata de catre Utilizator in termen de cel mult 30 de zile calendaristice de la data achizitiei produsului reclamat/contestat. Ea va fi solutionata in cel mult 30 de zile de la data primirii sesizarii. In situatia in care reclamatia nu a fost solutionata in acest termen, Secom® va trimite o informare scrisa cu privire la motivele pentru care aceasta nu a fost solutionata, in termen de cel mult 5 zile de la data expirarii termenului de solutionare.
  
Sesizarea va contine cel putin urmatoarele elemente:

  - numele complet al persoanei care adreseaza reclamatia (denumirea, in cazul persoanei juridice)
  - calitatea acestuia (numai daca persoana care semneaza plangerea este imputernicit al unei alte persoane ori reprezentant al unei societati comerciale)
  - datele de identificare (domiciliu,  in cazul persoanei fizice/sediul social, Cod Unic de Inregistrare, numar de ordine la Oficiul Registrului Comertului, in cazul persoanei juridice)
  - descrierea completa a defectului/celor reclamate, precum si numarul facturii si data achizitionarii produselor/serviciilor
  - adresa corecta si completa la care raspunsul Secom® va fi expediat
  - data intocmirii reclamatiei
  - semnatura persoanei care formuleaza reclamatia (si stampila, in cazul persoanelor juridice).

Orice reclamatie/sesizare expediata in termen si care va cuprinde toate elementele enumerate anterior va fi solutionata in termen de 30 de zile de la data primirii acesteia. Secom® nu poate garanta solutionarea favorabila a reclamatiei/sesizarii. In situatia in care rUtilizatorul nu va considera solutia oferita de catre Secom® drept potrivita, acesta se va putea adresa Autoritatii Nationale pentru Protectia Consumatorului (link), iar in situatia in care nici masurile dispuse astfel nu sunt satisfacatoare, instantei judecatoresti competente.  

Magazinul nostru respecta standardele TRUSTED.ro si detine Marca de Incredere.

FORTA MAJORA

Niciuna dintre partile contractuale nu poate fi trasa la raspundere pentru neexecutarea (totala/partiala) sau executarea cu intarziere a obligatiilor sale, daca acestea au fost cauzate de forta majora. Partile isi vor aduce la cunostita de indata cazul de forta majora si vor lua toate masurile necesare in vederea limitarii consecintelor evenimentului.  Daca in termen de 15 zile evenimentul de forta majora nu inceteaza, partile au dreptul de a denunta unilateral contractul fara a putea pretinde plata de daune-interese.

Forta majora va fi probata conform legii.

SOLUTIONAREA CONFLICTELOR. LEGEA APLICABILA

Contractul va fi guvernat si interpretat in conformitate cu legea romana. Orice conflict aparut intre secom.ro si  Utilizator va fi rezolvat pe cale amiabila. In cazul in care acest lucru nu este posibil, prima cale de solutionare este medierea, in conditiile legii, iar daca aceasta esueaza se va apela la instantele judecatoresti competente. 

FRAUDA

Crearea de conturi multiple folosind adrese generate automat si care expira dupa o perioada predefinita, pentru a putea beneficia de promotii sau oferte, sau orice tip de comportament de natura a afecta bunul mers al promotiilor/ofertelor/concursurilor, sunt interzise si vor fi considerate drept tentative de frauda. Proprietarul site-ului secom.ro isi rezerva dreptul de a suspenda conturile astfel create si de a retrage beneficiile aferente promotiilor/ofertelor/concursurilor in curs, de a anula contul fara preaviz, instiintare, alte formalitati sau despagubiri. Orice tentativa de frauda sau orice frauda (cum ar fi dar fara a se limita la: accesarea datelor Utilizatorilor secom.ro, alterarea continutului site-ului, incercarea de afectare a performantelor serverelor secom.ro, deturnarea continutului livrarilor catre terti, etc.) vor fi pedepsite conform legii penale.

DISPOZITII FINALE

Daca oricare dintre clauzele de mai sus va deveni nula sau nevalida, acest fapt nu va afecta valabilitatea tuturor celorlalte clauze. 

DATE DE IDENTIFICARE

  Societatea Secom Healthcare S.R.L. Nr.ord.reg.com.: J40/9299/1991
  C.U.I: RO8978457
  IBAN: RO15 BACX 0000 0002 9510 9064
  Banca: Unicredit Bank – Suc. Titulescu

SERVICIILE OFERITE IN CENTRUL DE MEDICINA FUNCTIONALA SI INTEGRATIVA

Serviciile puse la dispozitia consumatorilor pentru consultatii la Centrul de Medicina Functionala si Integrativa sunt oferite in parteneriat cu:

  Integrative Medicine Clinic S.R.L., cu sediul social in Sos. Gh. Ionescu Sisesti, nr. 8A, Sector 1, Bucuresti  Cod Unic de Inregistrare 43142785, numar de inregistrare la Registrul Comertului J40/12885/2020, punct de lucru in Sos. Gh. Ionescu Sisesti, nr. 8A, Sector 1, Bucuresti in incinta Enayati Medical City, telefon 0733 354 000 / 021 316 43 92, email: contact@verithera.ro .

Prin intermediul formularelor din paginile Centrului de Medicina Functionala si Integrativa , secom.ro faciliteaza transmiterea de informatii catre Integrative Medicine Clinic SRL, pentru a va putea programa la o consultatie in cadrul Centrului de Medicina Functioanala si Integrativa.
`,
};
