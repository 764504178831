// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import theme from '../assets/theme';
import { AuthContext, useAuthContext } from '../auth/AuthContext';
import { getLocation, isUserLoggedIn } from '../auth/utils/utils';
import { StateContext } from '../state/StateContext';
import { getCountiesFromStorage } from '../state/utils';
import CreateMembership from '../views/CreateMembership';
import Login from '../views/Login';
import SelectLocation from '../views/SelectLocation';

function RequireAuth({ children }: any) {
  const authenticated = useAuthContext();

  return authenticated.isAuthenticated === true ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
}

function RequireLocation({ children }: any) {
  const authenticated = useAuthContext();

  return authenticated.location ? (
    children
  ) : (
    <Navigate to="/select-location" replace />
  );
}

export function App() {
  const [authState, setAuthState] = useState({
    isAuthenticated: isUserLoggedIn(),
    location: getLocation(),
  });

  const [state, setState] = useState({
    counties: getCountiesFromStorage(),
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ ...authState, setAuthState }}>
        <StateContext.Provider value={{ ...state, setState }}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <RequireLocation>
                      <CreateMembership />
                    </RequireLocation>
                  </RequireAuth>
                }
              />
              <Route
                path="/login"
                element={
                  !authState.isAuthenticated ? (
                    <Login />
                  ) : (
                    <Navigate to="/select-location" replace />
                  )
                }
              />
              <Route
                path="select-location"
                element={
                  !authState.location ? (
                    <RequireAuth>
                      <SelectLocation />
                    </RequireAuth>
                  ) : (
                    <Navigate to="/create-membership" replace />
                  )
                }
              />
              <Route
                path="/create-membership"
                element={
                  <RequireAuth>
                    <RequireLocation>
                      <CreateMembership />
                    </RequireLocation>
                  </RequireAuth>
                }
              />
            </Routes>
          </BrowserRouter>
        </StateContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
