import { LOCAL_STORAGE_CONSTANTS, API_URL } from './../constants/api.constants';
import axios from 'axios';
import { ICreateCard } from '../interfaces/card.interface';

export const createCard = async (cardDTO: ICreateCard): Promise<any> => {
  const apiURL = `${process.env.REACT_APP_API_URL}${API_URL.CREATE_CARD}`;

  const token = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN);

  if (!token) {
    return null;
  }

  return axios
    .post<ICreateCard>(apiURL, cardDTO, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};
