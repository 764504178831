import { LOCAL_STORAGE_CONSTANTS, API_URL } from './../constants/api.constants';
import axios from 'axios';
import { ICounty, IStore } from '../interfaces/location.interface';

export const getStores = async (): Promise<IStore[] | null> => {
  const apiURL = `${process.env.REACT_APP_API_URL}${API_URL.STORES}`;

  const token = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN);

  if (!token) {
    return null;
  }

  return axios
    .get<IStore[]>(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const getCountiesAndCities = async (): Promise<ICounty[] | null> => {
  const apiURL = `${process.env.REACT_APP_API_URL}${API_URL.COUNTIES}`;

  const token = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN);

  if (!token) {
    return null;
  }

  return axios
    .get<ICounty[]>(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      localStorage.setItem(
        LOCAL_STORAGE_CONSTANTS.COUNTIES,
        JSON.stringify(res.data)
      );
      return res.data;
    });
};
