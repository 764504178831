export const isUserLoggedIn = (): boolean => {
  const token = localStorage.getItem('accessToken');
  return token !== null && token.length > 0;
};

export const getLocation = (): string => {
  const location = localStorage.getItem('location');
  return location || '';
};

export const getAuthToken = (): string => {
  const token = localStorage.getItem('accessToken');
  return token ? token : '';
};
