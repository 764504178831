import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import logo from "../assets/images/logo_secom.svg";
import { MESSAGES } from "../assets/messages";
import { useAuthContext } from "../auth/AuthContext";
import ErrorModal from "../components/ErrorModal";
import { LOCAL_STORAGE_CONSTANTS } from "../constants/api.constants";
import { IStore } from "../interfaces/location.interface";
import { getStores } from "../providers/locations.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex !important",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      padding: "2rem",
    },
    form: {
      width: "50%!important",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "2rem",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      margin: "2rem",
    },
    input: {
      display: "block",
      marginBottom: "1rem !important",
      width: "100% !important",
    },
    primaryButton: {
      background: theme.palette.primary.main,
      color: "#ffffff !important",
    },
  })
);

const SelectLocation = () => {
  const [location, setLocation] = useState<string>("");
  const [locations, setLocations] = useState<IStore[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const theme = useTheme();
  const classes = useStyles(theme);
  const { isAuthenticated, setAuthState } = useAuthContext();

  useEffect(() => {
    setIsLoading(true);
    getStores()
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setLocations(response);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        if(err?.response?.data?.message){
          setError(err?.response?.data?.message);
        } else {
          if(!navigator.onLine){
            setError(MESSAGES[`error.internet.access` as string]);
          } else{
            setError(MESSAGES[`error.500` as string]);
          }
        }
      });
  }, []);

  const handleSelect = (e: any) => {
    setLocation(e.target.value);
  };

  const onSubmitLocation = () => {
    if (location) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.LOCATION, location);
      setAuthState((state: any) => ({ ...state, location: location }));
    }
  };

  return (
    <>
    <Container className={classes.container}>
      <img src={logo} alt="Secom" />
      {isLoading && (
        <Backdrop open={isLoading} style={{ zIndex: 100 }}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <FormControl className={classes.form} fullWidth>
        <Typography variant="h4" my={3}>
          {MESSAGES["select.location"]}
        </Typography>
        <TextField
          fullWidth
          label={MESSAGES["location"]}
          value={location}
          onChange={handleSelect}
          className={classes.input}
          select
        >
          {locations.map((location) => {
            return (
              <MenuItem value={location.name} key={location.id}>
                {location.name}
              </MenuItem>
            );
          })}
        </TextField>
        <Button
          variant="contained"
          className={classes.primaryButton}
          onClick={onSubmitLocation}
        >
          {MESSAGES["pick.location"]}
        </Button>
      </FormControl>
    </Container>
    <ErrorModal error={error} onClose={setError.bind(null,'')}/>
    </>
  );
};

export default SelectLocation;
