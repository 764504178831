export const LOCAL_STORAGE_CONSTANTS = {
  ACCESS_TOKEN: 'accessToken',
  STORES: 'stores',
  COUNTIES: 'counties',
  LOCATION: 'location',
};

export const API_URL = {
  STORES: 'localization/stores',
  COUNTIES: 'localization/county/full',
  LOGIN: 'auth/login',
  CREATE_CARD: 'api/customer/membership',
};
