export const MESSAGES: Record<string, string> = {
  login: 'Login',
  logout: 'Logout',
  username: 'Username',
  password: 'Parolă',
  error: 'Eroare',
  location: 'Locație',
  'error.500': 'A apărut o eroare necunoscută.',
  'error.401': 'Trebuie să vă logați pentru a avea acces.',
  'error.internet.access': 'Verificați conexiunea la internet',
  'login.error.401': 'Username-ul sau parola sunt greșite.',
  'username.required': 'Vă rugăm să introduceți numele de utilizator!',
  'password.required': 'Vă rugăm să introduceți parola!',
  'select.location': 'Selectează magazinul Secom:',
  'pick.location': 'Alege magazinul',
  'card.number': 'Cod card fidelitate',
  'card.number.required': 'Vă rugăm să introduceți numărul cardului!',
  'card.number.format': 'Formatul numărului de card este greșit!',
  'card.lastName': 'Nume',
  'card.lastName.required': 'Vă rugăm să introduceți numele!',
  'card.lastName.length': 'Numele trebuie să conțină între 2 și 20 caractere!',
  'card.lastName.format': 'Formatul numelui nu este valid',
  'card.firstName': 'Prenume',
  'card.firstName.required': 'Vă rugăm să introduceți prenumele!',
  'card.firstName.length':
    'Prenumele trebuie să conțină între 2 și 20 caractere!',
  'card.firstName.format': 'Formatul prenumelui nu este valid',
  'card.email': 'Email',
  'card.email.format': 'Formatul email-ului este invalid.',
  'card.email.required': 'Vă rugăm să introduceți emailul!',
  'card.noEmail': 'Nu are email',
  'card.birthday': 'Data nașterii',
  'card.birthday.required': 'Introduceți data nașterii',
  'card.birthday.min': 'Data nu poate fi înainte de 01.01.1900',
  'card.birthday.max': 'Data nu poate fi în viitor',
  'card.gender.male': 'Masculin',
  'card.gender.female': 'Feminin',
  'card.phone': 'Telefon mobil',
  'card.phone.required': 'Vă rugăm să introduceți numărul de telefon!',
  'card.phone.format': 'Formatul numărului de telefon este greșit!',
  'card.hasKids': 'Aveți copii sub 12 ani?',
  'card.hasKids.yes': 'Da',
  'card.hasKids.no': 'Nu',
  'card.county': 'Județ',
  'card.county.required': 'Vă rugăm să selectați un județ.',
  'card.city': 'Oraș',
  'card.city.required': 'Vă rugăm să selectați un oraș.',
  'card.source': 'De unde ați aflat de noi?',
  'card.source.people': 'Cunoștințe, prieteni, familie',
  'card.source.site': 'Site secom.ro',
  'card.source.others': 'Alte surse online: cu răspuns deschis',
  'card.source.street': 'L-am văzut pe stradă și am intrat',
  'card.source.required': 'Vă rugăm să alegeți sursa',
  'card.source.length': 'Sursa trebuie să conțină între 2 și 100 caractere!',
  'card.confirm': 'Confirmă',
  'card.reset': 'Resetează formular',
  'card.tos': 'Termenii și condițiile*',
  'card.tos.label':
    'Am citit și sunt de acord cu {} de prelucrare a datelor cu caracter personal',
  'card.tos.required':
    'Trebuie să acceptați termenii și condițiile pentru a crea cardul.',
  'card.marketing': 'informări în scopuri de marketing direct.',
  'card.marketing.label': 'Sunt de acord cu primirea de ',
  'card.marketing.byEmail': 'Email',
  'card.marketing.byPhone': 'Telefon',
  'card.marketing.form.required':
    'Trebuie să acceptați un mijloc de informare in scopuri de marketing direct (email sau telefon) pentru a crea cardul.',
  'card.offers': 'oferte personalizate',
  'card.success': 'Cardul de fidelitate a fost creat cu succes.',
};
