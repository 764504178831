import CancelIcon from '@mui/icons-material/Cancel';
import {
  Typography,
  IconButton,
  Theme,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { MODAL_TEXTS } from '../assets/modalTexts';
import { modalType } from '../constants/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      borderRadius: '20px important',
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    iconButton: {
      top: '-8px',
      right: '-16px',
      padding: '0px !important',
    },
    icon: {
      color: theme.palette.primary.main,
    },
    textWrap: {
      whiteSpace: 'pre-wrap',
    },
    actionBar: {
      justifyContent: 'center !important',
    },
    button: {
      color: 'white !important'
    }
  })
);

const TextModal = ({ variant, onClose }: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const TermsOfService = () => (
    <Typography component="pre" className={classes.textWrap}>
      {MODAL_TEXTS['tos']}
    </Typography>
  );

  const Marketing = () => (
    <Typography component="pre" className={classes.textWrap}>
      {MODAL_TEXTS['tos']}
    </Typography>
  );

  const Offers = () => (
    <Typography component="pre" className={classes.textWrap}>
      {MODAL_TEXTS['offers']}
    </Typography>
  );

  const getTitle = () => {
    switch (variant) {
      case modalType.TOS:
        return MODAL_TEXTS['tosTitle'];
      case modalType.MARKETING:
        return MODAL_TEXTS['tosTitle'];
      case modalType.OFFERS:
        return MODAL_TEXTS['offersTitle'];
      default:
        return;
    }
  };

  return (
    <Dialog
      open={variant !== ''}
      scroll="paper"
      maxWidth="lg"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {getTitle()}
        <IconButton className={classes.iconButton} onClick={onClose}>
          <CancelIcon className={classes.icon} fontSize="large" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {variant === modalType.TOS && <TermsOfService />}
        {variant === modalType.MARKETING && <Marketing />}
        {variant === modalType.OFFERS && <Offers />}
      </DialogContent>

      <DialogActions className={classes.actionBar}>
        <Button variant='contained' onClick={onClose} className={classes.button}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextModal;
