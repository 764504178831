export const loginValidations = {
  username: {
    required: {
      value: true,
      message: 'username.required',
    },
  },
  password: {
    required: {
      value: true,
      message: 'password.required',
    },
  },
};

export const formValidations = {
  cardNumber: {
    required: {
      value: true,
      message: 'card.number.required',
    },
    pattern: {
      value: /^\d{13}$/,
      message: 'card.number.format',
    },
  },
  lastName: {
    required: {
      value: true,
      message: 'card.lastName.required',
    },
    minLength: {
      value: 2,
      message: 'card.lastName.length',
    },
    maxLength: {
      value: 20,
      message: 'card.lastName.length',
    },
    pattern: {
      value: /^[a-zA-Z]+[-\s]{0,1}[a-zA-Z]+$/,
      message: 'card.lastName.format',
    },
  },
  firstName: {
    required: {
      value: true,
      message: 'card.firstName.required',
    },
    minLength: {
      value: 2,
      message: 'card.firstName.length',
    },
    maxLength: {
      value: 20,
      message: 'card.firstName.length',
    },
    pattern: {
      value: /^[a-zA-Z]+[-\s]{0,1}[a-zA-Z]+$/,
      message: 'card.firstName.format',
    },
  },
  email: {
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'card.email.format',
    },
  },
  birthDate: {
    required: {
      value: true,
      message: 'card.birthday.required',
    },
    validate: {
      minDate: date => date > new Date('1900-01-01T00:00:00') ? true : 'card.birthday.min',
      maxDate: date => date < new Date() ? true : 'card.birthday.max',
    }
  },
  phoneNumber: {
    required: {
      value: true,
      message: 'card.phone.required',
    },
    pattern: {
      value: /^07\d{8}$/,
      message: 'card.phone.format',
    },
  },
  county: {
    required: {
      value: true,
      message: 'card.county.required',
    },
  },
  city: {
    required: {
      value: true,
      message: 'card.city.required',
    },
  },
  leadSource: {
    required: {
      value: true,
      message: 'card.source.required',
    },
    minLength: {
      value: 2,
      message: 'card.source.length',
    },
    maxLength: {
      value: 100,
      message: 'card.source.length',
    },
  },
};
